import { ICompany } from 'app/types/company';
import { Dispatch, SetStateAction, useState } from 'react';
import { createContext } from 'react';

interface ConfigSystemState {
  defaultLogoURL?: string | null;
  systemCompany: ICompany | null;
  setDefaultLogoURL: (logo?: string | null) => void;
  setSystemCompany: Dispatch<SetStateAction<ICompany | null>>;
}

const ConfigSystemContext = createContext<ConfigSystemState>({
  defaultLogoURL: null,
  systemCompany: null,
  setDefaultLogoURL: () => {},
  setSystemCompany: () => {},
});

export const ConfigSystemProvider: React.FC = ({ children }) => {
  const [defaultLogoURL, setDefaultLogoURL] = useState<
    string | null | undefined
  >(null);
  const [systemCompany, setSystemCompany] = useState<ICompany | null>(null);

  return (
    <ConfigSystemContext.Provider
      value={{
        defaultLogoURL,
        systemCompany,
        setDefaultLogoURL,
        setSystemCompany,
      }}
    >
      {children}
    </ConfigSystemContext.Provider>
  );
};

export default ConfigSystemContext;
