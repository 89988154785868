export enum NodeEnv {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
}

export enum ReactQueryKey {
  STATION_LIST = 'stations',
  STATION_ITEM = 'station',
  STATION_STATISTIC = 'station-statistic',
  CONFIG_SYSTEM_LOGO_URL = 'config-system-logo-url',
}

export enum ELanguages {
  EN = 'en',
  VI = 'vi',
}

export enum EModeThemes {
  DARK_THEME = 'DARK_THEME',
  LIGHT_THEME = 'LIGHT_THEME',
}

export enum LanguageTranslateText {
  // For message translate
  MSG_ERROR = 'MSG_ERROR',

  // For normal text translate
  TEXT_NORMAL = 'TEXT_NORMAL',
  TEXT_LOGIN = 'TEXT_LOGIN',
  TEXT_REGISTER = 'TEXT_REGISTER',
  TEXT_LOGOUT = 'TEXT_LOGOUT',

  TEXT_ID = 'TEXT_ID',
  TEXT_TIME = 'TEXT_TIME',
  TEXT_STATION = 'TEXT_STATION',
  TEXT_STATION_ADMIN = 'TEXT_STATION_ADMIN',
  TEXT_STATION_ID = 'TEXT_STATION_ID',
  TEXT_STATION_TYPE_KEY = 'TEXT_STATION_TYPE_KEY',
  TEXT_STATION_NAME = 'TEXT_STATION_NAME',
  TEXT_STATION_CODE = 'TEXT_STATION_CODE',
  TEXT_STATION_PATH = 'TEXT_STATION_PATH',
  TEXT_STATION_COLOR = 'TEXT_STATION_COLOR',
  TEXT_STATION_STRUCTURE_TYPE = 'TEXT_STATION_STRUCTURE_TYPE',
  TEXT_STATION_DATA_TYPE = 'TEXT_STATION_DATA_TYPE',
  TEXT_STATION_TIME_SYNC = 'TEXT_STATION_TIME_SYNC',
  TEXT_STATION_COMPANY = 'TEXT_STATION_COMPANY',
  TEXT_STATION_COMPANY_SLOGAN = 'TEXT_STATION_COMPANY_SLOGAN',
  TEXT_STATION_COMPANY_LOGO = 'TEXT_STATION_COMPANY_LOGO',
  TEXT_STATION_COMPANY_ADDRESS = 'TEXT_STATION_COMPANY_ADDRESS',
  TEXT_STATION_COMPANY_POSTAL_CODE = 'TEXT_STATION_COMPANY_POSTAL_CODE',
  TEXT_STATION_COMPANY_EMAIL = 'TEXT_STATION_COMPANY_EMAIL',
  TEXT_STATION_COMPANY_WEBSITE = 'TEXT_STATION_COMPANY_WEBSITE',
  TEXT_STATION_COMPANY_TELEPHONE = 'TEXT_STATION_COMPANY_TELEPHONE',
  TEXT_STATION_CUSTOMER_COMPANY = 'TEXT_STATION_CUSTOMER_COMPANY',
  TEXT_STATION_CUSTOMER_COMPANY_MAX_STATIONS = 'TEXT_STATION_CUSTOMER_COMPANY_MAX_STATIONS',
  TEXT_STATION_CUSTOMER_COMPANY_PRIVATE_KEY = 'TEXT_STATION_CUSTOMER_COMPANY_PRIVATE_KEY',
  TEXT_STATION_CUSTOMER_COMPANY_DOMAIN = 'TEXT_STATION_CUSTOMER_COMPANY_DOMAIN',
  TEXT_STATION_START_TIME = 'TEXT_STATION_START_TIME',
  TEXT_STATION_END_TIME = 'TEXT_STATION_END_TIME',
  TEXT_STATION_BASIC_INFO = 'TEXT_STATION_BASIC_INFO',
  TEXT_STATION_CONFIG_PARAMS = 'TEXT_STATION_CONFIG_PARAMS',
  TEXT_USER_BASIC_INFO = 'TEXT_USER_BASIC_INFO',
  TEXT_USER_CHANGE_PASSWORD = 'TEXT_USER_CHANGE_PASSWORD',
  TEXT_USER_NAME = 'TEXT_USER_NAME',
  TEXT_USER_USERNAME = 'TEXT_USER_USERNAME',
  TEXT_USER_EMAIL = 'TEXT_USER_EMAIL',
  TEXT_USER_PASSWORD = 'TEXT_USER_PASSWORD',
  TEXT_USER_OLD_PASSWORD = 'TEXT_USER_OLD_PASSWORD',
  TEXT_USER_NEW_PASSWORD = 'TEXT_USER_NEW_PASSWORD',
  TEXT_USER_CONFIRM_PASSWORD = 'TEXT_USER_CONFIRM_PASSWORD',
  TEXT_USER_PHONE_NUMBER = 'TEXT_USER_PHONE_NUMBER',
  TEXT_USER_AVATAR = 'TEXT_USER_AVATAR',
  TEXT_USER_BIRTHDAY = 'TEXT_USER_BIRTHDAY',
  TEXT_USER_ROLE = 'TEXT_USER_ROLE',
  TEXT_USER_ROLE_ADMIN = 'TEXT_USER_ROLE_ADMIN',
  TEXT_USER_ROLE_USER = 'TEXT_USER_ROLE_USER',
  TEXT_USER_ROLE_OWNER = 'TEXT_USER_ROLE_OWNER',

  TEXT_TYPE_STATION = 'TEXT_TYPE_STATION',
  TEXT_TYPE_STATION_STATUS = 'TEXT_TYPE_STATION_STATUS',
  TEXT_TYPE_STATION_STATUS_ALL = 'TEXT_TYPE_STATION_STATUS_ALL',
  TEXT_TYPE_STATION_STATUS_OK = 'TEXT_TYPE_STATION_STATUS_OK',
  TEXT_TYPE_STATION_STATUS_DISCONNECTED = 'TEXT_TYPE_STATION_STATUS_DISCONNECTED',
  TEXT_TYPE_STATION_STATUS_MAINTENANCE = 'TEXT_TYPE_STATION_STATUS_MAINTENANCE',
  TEXT_TYPE_STATION_STATUS_ERROR_REPORT = 'TEXT_TYPE_STATION_STATUS_ERROR_REPORT',
  TEXT_TYPE_STATION_CONFIG_PARAMS_STATUS_OK = 'TEXT_TYPE_STATION_CONFIG_PARAMS_STATUS_OK',
  TEXT_TYPE_STATION_CONFIG_PARAMS_STATUS_MAINTENANCE = 'TEXT_TYPE_STATION_CONFIG_PARAMS_STATUS_MAINTENANCE',
  TEXT_TYPE_STATION_CONFIG_PARAMS_STATUS_ERROR = 'TEXT_TYPE_STATION_CONFIG_PARAMS_STATUS_ERROR',
  TEXT_TOTAL_STATION = 'TEXT_TOTAL_STATION',
  TEXT_STATION_STATUS_OK = 'TEXT_STATION_STATUS_OK',
  TEXT_STATION_STATUS_DISCONNECTED = 'TEXT_STATION_STATUS_DISCONNECTED',
  TEXT_STATION_STATUS_MAINTENANCE = 'TEXT_STATION_STATUS_MAINTENANCE',
  TEXT_STATION_STATUS_ERROR_REPORT = 'TEXT_STATION_STATUS_ERROR_REPORT',
  TEXT_STATION_MONITOR_WASTEWATER = 'TEXT_STATION_MONITOR_WASTEWATER',
  TEXT_STATION_MONITOR_AIR = 'TEXT_STATION_MONITOR_AIR',
  TEXT_STATION_MONITOR_GROUNDWATER = 'TEXT_STATION_MONITOR_GROUNDWATER',
  TEXT_STATION_MONITOR_WATER = 'TEXT_STATION_MONITOR_WATER',
  TEXT_STATION_MONITOR_EMISSIONS = 'TEXT_STATION_MONITOR_EMISSIONS',
  TEXT_STATION_CREATE = 'TEXT_STATION_CREATE',
  TEXT_COMPANY_CREATE = 'TEXT_COMPANY_CREATE',
  TEXT_USER_CREATE = 'TEXT_USER_CREATE',
  TEXT_USER_UPDATE = 'TEXT_USER_UPDATE',
  TEXT_STATION_TYPE_CREATE = 'TEXT_STATION_TYPE_CREATE',
  TEXT_STATION_CONFIGURATION = 'TEXT_STATION_CONFIGURATION',
  TEXT_STATION_MANAGE_CONFIGURATION = 'TEXT_STATION_MANAGE_CONFIGURATION',

  TEXT_TYPE_MONITOR = 'TEXT_TYPE_MONITOR',
  TEXT_TYPE_MONITOR_ONLINE = 'TEXT_TYPE_MONITOR_ONLINE',
  TEXT_TYPE_MONITOR_CHART = 'TEXT_TYPE_MONITOR_CHART',
  TEXT_TYPE_MONITOR_TABLE = 'TEXT_TYPE_MONITOR_TABLE',

  TEXT_MANAGE = 'TEXT_MANAGE',
  TEXT_MANAGE_USER = 'TEXT_MANAGE_USER',
  TEXT_MANAGE_STATION = 'TEXT_MANAGE_STATION',
  TEXT_MANAGE_STATION_TYPE = 'TEXT_MANAGE_STATION_TYPE',
  TEXT_MANAGE_COMPANIES = 'TEXT_MANAGE_COMPANIES',
  TEXT_MANAGE_INSIDE_COMPANIES = 'TEXT_MANAGE_INSIDE_COMPANIES',
  TEXT_MANAGE_OUTSIDE_COMPANIES = 'TEXT_MANAGE_OUTSIDE_COMPANIES',
  TEXT_MANAGE_NOTIFICATIONS = 'TEXT_MANAGE_NOTIFICATIONS',

  TEXT_REPORT = 'TEXT_REPORT',
  TEXT_SETTINGS = 'TEXT_SETTINGS',
  TEXT_SETTINGS_NOTIFICATIONS = 'TEXT_SETTINGs_NOTIFICATIONS',
  TEXT_SYSTEM_NAME = 'TEXT_SYSTEM_NAME',
  TEXT_APP_NAME = 'TEXT_APP_NAME',
  TEXT_USERNAME = 'TEXT_USERNAME',
  TEXT_PASSWORD = 'TEXT_PASSWORD',
  TEXT_DASHBOARD = 'TEXT_DASHBOARD',
  TEXT_INTEGRATED_STATISTICS = 'TEXT_INTEGRATED_STATISTICS',
  TEXT_STATION_STATUS = 'TEXT_STATION_STATUS',
  TEXT_SEARCH = 'TEXT_SEARCH',
  TEXT_APPLY = 'TEXT_APPLY',
  TEXT_CLEAR = 'TEXT_CLEAR',
  TEXT_CANCEL = 'TEXT_CANCEL',
  TEXT_CONFIRM = 'TEXT_CONFIRM',
  TEXT_ACTIONS = 'TEXT_ACTIONS',
  TEXT_DELETE = 'TEXT_DELETE',
  TEXT_EDIT = 'TEXT_EDIT',
  TEXT_CREATE = 'TEXT_CREATE',
  TEXT_EXPORT = 'TEXT_EXPORT',
  TEXT_ADD = 'TEXT_ADD',
  TEXT_ADD_CONFIG_PARAM = 'TEXT_ADD_CONFIG_PARAM',
  TEXT_ADD_STATION = 'TEXT_ADD_STATION',
  TEXT_LIMIT_STATION_CREATE = 'TEXT_LIMIT_STATION_CREATE',

  TEXT_DAY_OF_WEEK_MONDAY = 'Monday',
  TEXT_DAY_OF_WEEK_TUESDAY = 'Tuesday',
  TEXT_DAY_OF_WEEK_WEDNESDAY = 'Wednesday',
  TEXT_DAY_OF_WEEK_THURSDAY = 'Thursday',
  TEXT_DAY_OF_WEEK_FRIDAY = 'Friday',
  TEXT_DAY_OF_WEEK_SATURDAY = 'Saturday',
  TEXT_DAY_OF_WEEK_SUNDAY = 'Sunday',
  TEXT_MONTH_OF_YEAR_JANUARY = 'January',
  TEXT_MONTH_OF_YEAR_FEBRUARY = 'February',
  TEXT_MONTH_OF_YEAR_MARCH = 'March',
  TEXT_MONTH_OF_YEAR_APRIL = 'April',
  TEXT_MONTH_OF_YEAR_MAY = 'May',
  TEXT_MONTH_OF_YEAR_JUNE = 'June',
  TEXT_MONTH_OF_YEAR_JULY = 'July',
  TEXT_MONTH_OF_YEAR_AUGUST = 'August',
  TEXT_MONTH_OF_YEAR_SEPTEMBER = 'September',
  TEXT_MONTH_OF_YEAR_OCTOBER = 'October',
  TEXT_MONTH_OF_YEAR_NOVEMBER = 'November',
  TEXT_MONTH_OF_YEAR_DECEMBER = 'December',

  TEXT_VALIDATE_REQUIRED_EXISTS = 'TEXT_VALIDATE_REQUIRED_EXISTS',
  TEXT_VALIDATE_REQUIRED_VALID_EMAIL = 'TEXT_VALIDATE_REQUIRED_VALID_EMAIL',
  TEXT_VALIDATE_REQUIRED_VALID_PHONE_NUMBER = 'TEXT_VALIDATE_REQUIRED_VALID_PHONE_NUMBER',
  TEXT_VALIDATE_REQUIRED_MATCH_PASSWORD = 'TEXT_VALIDATE_REQUIRED_MATCH_PASSWORD',
  TEXT_VALIDATE_REQUIRED_PASSWORD_MIN = 'TEXT_VALIDATE_REQUIRED_PASSWORD_MIN',
  TEXT_VALIDATE_REQUIRED_POSITIVE_NUMBER = 'TEXT_VALIDATE_REQUIRED_POSITIVE_NUMBER',
  TEXT_VALIDATE_REQUIRED_MAX_GREATER_THAN_MIN = 'TEXT_VALIDATE_REQUIRED_MAX_GREATER_THAN_MIN',
  TEXT_VALIDATE_REQUIRED_AT_LEAST_ONE_CONFIG_PARAM = 'TEXT_VALIDATE_REQUIRED_AT_LEAST_ONE_CONFIG_PARAM',
  TEXT_VALIDATE_REQUIRED_AT_LEAST_ONE_ITEM = 'TEXT_VALIDATE_REQUIRED_AT_LEAST_ONE_ITEM',
  TEXT_VALIDATE_END_TIME_BEFORE_START_TIME = 'TEXT_VALIDATE_END_TIME_BEFORE_START_TIME',
  TEXT_VALIDATE_STATION_NAME_NOT_VALID = 'TEXT_VALIDATE_STATION_NAME_NOT_VALID',
  TEXT_VALIDATE_IMAGE_TYPE_NOT_SUPPORT = 'TEXT_VALIDATE_IMAGE_TYPE_NOT_SUPPORT',
  TEXT_VALIDATE_MUST_ADD_AT_LEAST_ONE_COMPANY = 'TEXT_VALIDATE_MUST_ADD_AT_LEAST_ONE_COMPANY',
  TEXT_VALIDATE_MIN_NUMBER_OF_MAX_STATIONS = 'TEXT_VALIDATE_MIN_NUMBER_OF_MAX_STATIONS',

  TEXT_STATION_CONFIG_PARAMS_CARD = 'TEXT_STATION_CONFIG_PARAMS_CARD',
  TEXT_STATION_CONFIG_PARAMS_CHART = 'TEXT_STATION_CONFIG_PARAMS_CHART',
  TEXT_STATION_CONFIG_PARAMS_REALTIME = 'TEXT_STATION_CONFIG_PARAMS_REALTIME',

  TEXT_STATION_CONFIG_PARAMS_NAME = 'TEXT_STATION_CONFIG_PARAMS_NAME',
  TEXT_STATION_CONFIG_PARAMS_CODE = 'TEXT_STATION_CONFIG_PARAMS_CODE',
  TEXT_STATION_CONFIG_PARAMS_UNIT = 'TEXT_STATION_CONFIG_PARAMS_UNIT',
  TEXT_STATION_CONFIG_PARAMS_VALUE = 'TEXT_STATION_CONFIG_PARAMS_VALUE',
  TEXT_STATION_CONFIG_PARAMS_STATUS = 'TEXT_STATION_CONFIG_PARAMS_STATUS',
  TEXT_STATION_CONFIG_PARAMS_STANDARD = 'TEXT_STATION_CONFIG_PARAMS_STANDARD',
  TEXT_STATION_CONFIG_PARAMS_NATIONAL = 'TEXT_STATION_CONFIG_PARAMS_NATIONAL',
  TEXT_STATION_CONFIG_PARAMS_LIMIT_CHART_MIN = 'TEXT_STATION_CONFIG_PARAMS_LIMIT_CHART_MIN',
  TEXT_STATION_CONFIG_PARAMS_LIMIT_CHART_MAX = 'TEXT_STATION_CONFIG_PARAMS_LIMIT_CHART_MAX',
  TEXT_STATION_CONFIG_PARAMS_WARNING_LIMIT_MIN = 'TEXT_STATION_CONFIG_PARAMS_WARNING_LIMIT_MIN',
  TEXT_STATION_CONFIG_PARAMS_WARNING_LIMIT_MAX = 'TEXT_STATION_CONFIG_PARAMS_WARNING_LIMIT_MAX',
  TEXT_STATION_CONFIG_PARAMS_NATIONAL_LIMIT_MIN = 'TEXT_STATION_CONFIG_PARAMS_NATIONAL_LIMIT_MIN',
  TEXT_STATION_CONFIG_PARAMS_NATIONAL_LIMIT_MAX = 'TEXT_STATION_CONFIG_PARAMS_NATIONAL_LIMIT_MAX',
  TEXT_STATION_CONFIG_PARAMS_LIMIT_CHART = 'TEXT_STATION_CONFIG_PARAMS_LIMIT_CHART',
  TEXT_STATION_CONFIG_PARAMS_WARNING_LIMIT = 'TEXT_STATION_CONFIG_PARAMS_WARNING_LIMIT',
  TEXT_STATION_CONFIG_PARAMS_NATIONAL_LIMIT = 'TEXT_STATION_CONFIG_PARAMS_NATIONAL_LIMIT',

  TEXT_NOT_FOUND_ANY_ITEM = 'TEXT_NOT_FOUND_ANY_ITEM',

  TEXT_CURRENT_VALUE = 'TEXT_CURRENT_VALUE',
  TEXT_MAX_VALUE = 'TEXT_MAX_VALUE',
  TEXT_MIN_VALUE = 'TEXT_MIN_VALUE',
  TEXT_OTHERS = 'TEXT_OTHERS',
  TEXT_PARAMETERS = 'TEXT_PARAMETERS',

  TEXT_OVERVIEW = 'TEXT_OVERVIEW',
  TEXT_MONITOR_STATION = 'TEXT_MONITOR_STATION',
  TEXT_COMMON = 'TEXT_COMMON',

  TEXT_TAB_TYPE_ACCOUNT = 'TEXT_TAB_TYPE_ACCOUNT',
  TEXT_TAB_TYPE_SECURE = 'TEXT_TAB_TYPE_SECURE',
  TEXT_TAB_TYPE_COMPANY_SETTINGS = 'TEXT_TAB_TYPE_COMPANY_SETTINGS',

  TEXT_TYPE_REPORT = 'TEXT_TYPE_REPORT',
  TEXT_REPORT_TYPE_ONE_MINUTE = 'TEXT_REPORT_TYPE_ONE_MINUTE',
  TEXT_REPORT_TYPE_FIVE_MINUTE = 'TEXT_REPORT_TYPE_FIVE_MINUTE',
  TEXT_REPORT_TYPE_THIRTY_MINUTE = 'TEXT_REPORT_TYPE_THIRTY_MINUTE',
  TEXT_REPORT_TYPE_ONE_HOUR = 'TEXT_REPORT_TYPE_ONE_HOUR',
  TEXT_REPORT_TYPE_ONE_DAY = 'TEXT_REPORT_TYPE_ONE_DAY',
  TEXT_REPORT_TYPE_ONE_MONTH = 'TEXT_REPORT_TYPE_ONE_MONTH',
  TEXT_REPORT_INVALID_DATA = 'TEXT_REPORT_INVALID_DATA',
  TEXT_REPORT_FILTER_STATION = 'TEXT_REPORT_FILTER_STATION',
  TEXT_REPORT_TABLE = 'TEXT_REPORT_TABLE',
  TEXT_REPORT_CHART = 'TEXT_REPORT_CHART',
  TEXT_REPORT_NOT_CHOOSE_STATION = 'TEXT_REPORT_NOT_CHOOSE_STATION',

  TEXT_ALERT_MESSAGE_FETCH_DATA_FAILED = 'TEXT_ALERT_MESSAGE_FETCH_DATA_FAILED',
  TEXT_ALERT_MESSAGE_CREATE_SUCCESS = 'TEXT_ALERT_MESSAGE_CREATE_SUCCESS',
  TEXT_ALERT_MESSAGE_CREATE_FAILED = 'TEXT_ALERT_MESSAGE_CREATE_FAILED',
  TEXT_ALERT_MESSAGE_UPDATE_SUCCESS = 'TEXT_ALERT_MESSAGE_UPDATE_SUCCESS',
  TEXT_ALERT_MESSAGE_UPDATE_FAILED = 'TEXT_ALERT_MESSAGE_UPDATE_FAILED',
  TEXT_ALERT_MESSAGE_DELETE_SUCCESS = 'TEXT_ALERT_MESSAGE_DELETE_SUCCESS',
  TEXT_ALERT_MESSAGE_DELETE_FAILED = 'TEXT_ALERT_MESSAGE_DELETE_FAILED',
  TEXT_ALERT_MESSAGE_EXPORT_SUCCESS = 'TEXT_ALERT_MESSAGE_EXPORT_SUCCESS',
  TEXT_ALERT_MESSAGE_EXPORT_FAILED = 'TEXT_ALERT_MESSAGE_EXPORT_FAILED',
  TEXT_ALERT_MESSAGE_PLEASE_LOGIN_WITH_NEW_PASSWORD = 'TEXT_ALERT_MESSAGE_PLEASE_LOGIN_WITH_NEW_PASSWORD',
  TEXT_ALERT_MESSAGE_REGISTER_SUCCESS = 'TEXT_ALERT_MESSAGE_REGISTER_SUCCESS',

  TEXT_CONFIRM_ALERT_TITLE = 'TEXT_CONFIRM_ALERT_TITLE',
  TEXT_CONFIRM_ACTION_CANNOT_UNDONE = 'TEXT_CONFIRM_ACTION_CANNOT_UNDONE',

  TEXT_NOTIFICATIONS_STATUS = 'TEXT_NOTIFICATIONS_STATUS',
  TEXT_NOTIFICATIONS_STATUS_PENDING = 'TEXT_NOTIFICATIONS_STATUS_PENDING',
  TEXT_NOTIFICATIONS_STATUS_FAILED = 'TEXT_NOTIFICATIONS_STATUS_FAILED',
  TEXT_NOTIFICATIONS_STATUS_SUCCEEDED = 'TEXT_NOTIFICATIONS_STATUS_SUCCEEDED',
  TEXT_NOTIFICATIONS_TYPE = 'TEXT_NOTIFICATIONS_TYPE',
  TEXT_NOTIFICATIONS_EMAIL = 'TEXT_NOTIFICATIONS_EMAIL',
  TEXT_NOTIFICATIONS_ZALO = 'TEXT_NOTIFICATIONS_ZALO',
  TEXT_NOTIFICATIONS_SMS = 'TEXT_NOTIFICATIONS_SMS',
  TEXT_NOTIFICATIONS_APP = 'TEXT_NOTIFICATIONS_APP',
  TEXT_NOTIFICATIONS_CONTENT = 'TEXT_NOTIFICATIONS_CONTENT',
  TEXT_NOTIFICATIONS_STATIONS_NAME = 'TEXT_NOTIFICATIONS_STATIONS_NAME',
  TEXT_NOTIFICATIONS_TIME = 'TEXT_NOTIFICATIONS_TIME',
  TEXT_NOTIFICATIONS_ALERT_CONFIGURATIONS = 'TEXT_NOTIFICATIONS_ALERT_CONFIGURATIONS',
  TEXT_NOTIFICATIONS_ALERT_CONFIGURATIONS_MAIL = 'TEXT_NOTIFICATIONS_ALERT_CONFIGURATIONS_MAIL',
  TEXT_NOTIFICATIONS_ALERT_CONFIGURATIONS_ZALO = 'TEXT_NOTIFICATIONS_ALERT_CONFIGURATIONS_ZALO',
  TEXT_NOTIFICATIONS_ALERT_CONFIGURATIONS_APP = 'TEXT_NOTIFICATIONS_ALERT_CONFIGURATIONS_APP',
  TEXT_NOTIFICATIONS_ALERT_CONFIGURATIONS_BASIC_INFO = 'TEXT_NOTIFICATIONS_ALERT_CONFIGURATIONS_BASIC_INFO',
  TEXT_NOTIFICATIONS_ALERT_TYPE_DISCONNECTED = 'TEXT_NOTIFICATIONS_ALERT_TYPE_DISCONNECTED',
  TEXT_NOTIFICATIONS_ALERT_TYPE_STATUS_ERROR = 'TEXT_NOTIFICATIONS_ALERT_TYPE_STATUS_ERROR',
  TEXT_NOTIFICATIONS_ALERT_TYPE_CROSS_NATIONAL_LINE_ERROR = 'TEXT_NOTIFICATIONS_ALERT_TYPE_CROSS_NATIONAL_LINE_ERROR',
  TEXT_NOTIFICATIONS_ALERT_TYPE_CROSS_WARNING_LINE_ERROR = 'TEXT_NOTIFICATIONS_ALERT_TYPE_CROSS_WARNING_LINE_ERROR',
  TEXT_NOTIFICATIONS_ALERT_TYPE_NORMAL = 'TEXT_NOTIFICATIONS_ALERT_TYPE_NORMAL',
  TEXT_NOTIFICATIONS_ALERT_FIELD_STATION_ID = 'TEXT_NOTIFICATIONS_ALERT_FIELD_STATION_ID',
  TEXT_NOTIFICATIONS_ALERT_FIELD_TIME_WARNING_DISCONNECT = 'TEXT_NOTIFICATIONS_ALERT_FIELD_TIME_WARNING_DISCONNECT',
  TEXT_NOTIFICATIONS_ALERT_FIELD_NUMBER_NOTIFY_NORMAL_DATA = 'TEXT_NOTIFICATIONS_ALERT_FIELD_NUMBER_NOTIFY_NORMAL_DATA',
  TEXT_NOTIFICATIONS_ALERT_FIELD_TIME_RE_ALERT = 'TEXT_NOTIFICATIONS_ALERT_FIELD_TIME_RE_ALERT',
  TEXT_NOTIFICATIONS_ALERT_FIELD_SENDER_NAME = 'TEXT_NOTIFICATIONS_ALERT_FIELD_SENDER_NAME',
  TEXT_NOTIFICATIONS_ALERT_FIELD_SENDER_MAIL = 'TEXT_NOTIFICATIONS_ALERT_FIELD_SENDER_MAIL',
  TEXT_NOTIFICATIONS_ALERT_FIELD_ZALO_RECEIVERS = 'TEXT_NOTIFICATIONS_ALERT_FIELD_ZALO_RECEIVERS',
  TEXT_NOTIFICATIONS_ALERT_FIELD_MAIL_RECEIVERS = 'TEXT_NOTIFICATIONS_ALERT_FIELD_MAIL_RECEIVERS',
  TEXT_NOTIFICATIONS_ALERT_FIELD_CONFIG_ALERT = 'TEXT_NOTIFICATIONS_ALERT_FIELD_CONFIG_ALERT',
  TEXT_NOTIFICATIONS_ALERT_FIELD_ENABLE = 'TEXT_NOTIFICATIONS_ALERT_FIELD_ENABLE',

  TEXT_HELPER_NOTICE_ZALO_NUMBER = 'TEXT_HELPER_NOTICE_ZALO_NUMBER',

  TEXT_MESSAGE_REGISTER_RESPONSE = 'TEXT_MESSAGE_REGISTER_RESPONSE',
}
