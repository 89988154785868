const SIDEBAR_WIDTH = process.env.REACT_APP_SIDEBAR_WIDTH
  ? parseInt(process.env.REACT_APP_SIDEBAR_WIDTH)
  : 240;

const SIDEBAR_MIN_WIDTH = process.env.REACT_APP_SIDEBAR_MIN_WIDTH
  ? parseInt(process.env.REACT_APP_SIDEBAR_MIN_WIDTH)
  : 73;

export const sidebarConfig = {
  SIDEBAR_WIDTH,
  SIDEBAR_MIN_WIDTH,
};
