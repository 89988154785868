import { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EDrawerType, EModalType } from './enum';
import { AlertProps } from '@mui/material';

interface IFeedbackComponent<T> {
  open: boolean;
  type: T | null;
  props: object | null;
}

interface IAlertState {
  open: boolean;
  status: AlertProps['color'] | null;
  message: string;
}

type TAlertActions = 'success' | 'info' | 'warning' | 'error';

interface UIState {
  modal: IFeedbackComponent<EModalType>;
  drawer: IFeedbackComponent<EDrawerType>;
  alert: IAlertState;
  sidebarActiveKey: string | null;
  sidebarExpandKey: string | null;
  setSidebarActiveKey: (key: string | null) => void;
  setSidebarExpandKey: (key: string | null) => void;
  openModal: (type: EModalType, props?: object) => void;
  closeModal: () => void;
  openDrawer: (type: EDrawerType, props?: object) => void;
  closeDrawer: () => void;
  checkOpen: (
    variant: 'modal' | 'drawer',
    type: EModalType | EDrawerType
  ) => boolean;
  setAlert: (act: TAlertActions, message: string) => void;
  clearAlert: () => void;
}

const UIContext = createContext<UIState>({
  modal: {
    open: false,
    type: null,
    props: null,
  },
  drawer: {
    open: false,
    type: null,
    props: null,
  },
  alert: {
    open: false,
    status: null,
    message: '',
  },
  sidebarActiveKey: null,
  sidebarExpandKey: null,
  setSidebarActiveKey: () => {},
  setSidebarExpandKey: () => {},
  openModal: () => {},
  closeModal: () => {},
  openDrawer: () => {},
  closeDrawer: () => {},
  checkOpen: () => false,
  setAlert: () => {},
  clearAlert: () => {},
});

export const UIProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [sidebarActiveKey, setSidebarActiveKey] = useState<string | null>(
    () => {
      return history.location.pathname + history.location.search;
    }
  );
  const [sidebarExpandKey, setSidebarExpandKey] = useState<string | null>(null);
  const [modal, setModal] = useState<IFeedbackComponent<EModalType>>({
    open: false,
    type: null,
    props: null,
  });
  const [drawer, setDrawer] = useState<IFeedbackComponent<EDrawerType>>({
    open: false,
    type: null,
    props: null,
  });
  const [alert, setAlert] = useState<IAlertState>({
    open: false,
    status: null,
    message: '',
  });

  const openModal = (modalType: EModalType, modalProps?: object) => {
    setModal(prev => ({
      open: true,
      type: modalType,
      props: modalProps ? modalProps : null,
    }));
  };
  const closeModal = () => {
    setModal({
      open: false,
      type: null,
      props: null,
    });
  };

  const openDrawer = (drawerType: EDrawerType, drawerProps?: object) => {
    setDrawer(prev => ({
      open: true,
      type: drawerType,
      props: drawerProps ? drawerProps : null,
    }));
  };
  const closeDrawer = () => {
    setDrawer({
      open: false,
      type: null,
      props: null,
    });
  };

  const checkOpen = (
    variant: 'modal' | 'drawer',
    type: EModalType | EDrawerType
  ): boolean => {
    if (variant === 'modal' && (type as EModalType)) {
      return modal.type === type;
    }
    if (variant === 'drawer' && (type as EDrawerType)) {
      return drawer.type === type;
    }

    return false;
  };

  const handleSetAlert = (act: TAlertActions, message: string) => {
    setAlert({
      open: true,
      status: act,
      message,
    });
  };

  const handleClearAlert = () => {
    setAlert({
      open: false,
      status: null,
      message: '',
    });
  };

  return (
    <UIContext.Provider
      value={{
        modal,
        alert,
        drawer,
        sidebarActiveKey,
        sidebarExpandKey,
        setSidebarActiveKey,
        setSidebarExpandKey,
        openModal,
        closeModal,
        openDrawer,
        closeDrawer,
        checkOpen,
        setAlert: handleSetAlert,
        clearAlert: handleClearAlert,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export default UIContext;
