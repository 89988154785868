import { EStationStatus } from 'app/types/station';
import { LanguageTranslateText } from './enum';
import slugify from 'slugify';
import {
  IPaginationResponse,
  IPaginationServerResponse,
} from 'app/types/pagination';
import Decimal from 'decimal.js';

export const renderStationStatusSelection = (): {
  label: string;
  value: string;
}[] => {
  return [
    {
      label: LanguageTranslateText.TEXT_TYPE_STATION_STATUS_ALL,
      value: 'all',
    },
    {
      label: LanguageTranslateText.TEXT_TYPE_STATION_STATUS_OK,
      value: EStationStatus.OK,
    },
    {
      label: LanguageTranslateText.TEXT_TYPE_STATION_STATUS_DISCONNECTED,
      value: EStationStatus.DISCONNECTED,
    },
    {
      label: LanguageTranslateText.TEXT_TYPE_STATION_STATUS_MAINTENANCE,
      value: EStationStatus.MAINTENANCE,
    },
    {
      label: LanguageTranslateText.TEXT_TYPE_STATION_STATUS_ERROR_REPORT,
      value: EStationStatus.ERROR_REPORT,
    },
  ];
};

export const checkSidebarItemExpand = (
  sidebarItemLink: string,
  locationPathname: string
): boolean => {
  if (locationPathname.includes(sidebarItemLink)) {
    return true;
  }

  return false;
};

export const randomIntFromInterval = (min: number, max: number): number => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const isEmpty = (str?: string | null): boolean => {
  if (!str) {
    return true;
  }
  return str.trim() === '';
};

export const mappingNumberToString = (number: number | null): number | null => {
  if (typeof number === 'number' && number > 0) {
    return number;
  }

  return null;
};

export const isInputNumberValid = (input: string | number): boolean => {
  if (isEmpty(input.toString())) {
    return true;
  }
  try {
    const inputInt = parseInt(input.toString());
    return Boolean(inputInt);
  } catch (error) {
    return false;
  }
};

export const isInputMaxNumberValid = (
  input: string | number,
  minValue: string | number
): boolean => {
  if (isEmpty(minValue.toString())) {
    return isInputNumberValid(input);
  }

  try {
    const inputInt = parseInt(input.toString());
    const minValueInt = parseInt(minValue.toString());

    return inputInt > minValueInt;
  } catch (err) {
    return false;
  }
};

export const renderMinMaxValues = (
  min: string | number,
  max: string | number,
  extra: string
): string => {
  const minString: string = isEmpty(min.toString()) ? 'N/A' : min.toString();
  const maxString: string = isEmpty(max.toString()) ? 'N/A' : max.toString();

  return `${minString} - ${maxString} (${extra})`;
};

export const renderCurrentValue = (params: {
  max?: number | string | null;
  min?: number | string | null;
  currentValue?: number | string | null;
}): {
  error: boolean;
  value: number | string;
} => {
  const { max, min, currentValue } = params;

  if (typeof currentValue === 'number' && isNaN(currentValue)) {
    return {
      error: true,
      value: 'N/A',
    };
  }

  const valueString = currentValue?.toString() || '';
  const maxString = max?.toString() || '';
  const minString = min?.toString() || '';

  // not valid: empty value
  if (isEmpty(valueString)) {
    return {
      error: true,
      value: 'N/A',
    };
  }

  // not valid: !empty max && max < value
  if (!isEmpty(maxString) && new Decimal(valueString).greaterThan(maxString)) {
    return {
      error: true,
      value: new Decimal(valueString).toNumber(),
    };
  }

  // not valid: !empty min && min > value
  if (!isEmpty(minString) && new Decimal(valueString).lessThan(minString)) {
    return {
      error: true,
      value: new Decimal(valueString).toNumber(),
    };
  }

  // valid
  return {
    error: false,
    value: new Decimal(valueString).toNumber(),
  };
};

export const generateExportInfo = (params: {
  stationId: number;
  paramId: number;
  stationName: string;
  paramName: string;
}): {
  chartId: string;
  brushChartId: string;
  chartIdForExport: string;
  brushChartIdForExport: string;
  filename: string;
} => {
  const { stationId, paramId, stationName, paramName } = params;
  const chartId: string = `${stationId}-${paramId}`;
  const brushChartId: string = `${stationId}-${paramId}-brush`;
  const chartIdForExport: string = `${stationId}x${paramId}`;
  const brushChartIdForExport: string = `${stationId}x${paramId}xbrush`;
  const filename: string = `${slugify(stationName, '-')}-${slugify(
    paramName,
    '-'
  )}.png`;

  return {
    chartId,
    brushChartId,
    chartIdForExport,
    brushChartIdForExport,
    filename,
  };
};

export const mappingPaginationServerToClient = <
  T extends unknown,
  U extends unknown
>(params: {
  paginationData: IPaginationServerResponse<T>;
  mappingFunc: (input: T) => U;
}): IPaginationResponse<U> => {
  const { paginationData, mappingFunc } = params;
  return {
    totalDocs: parseInt(paginationData.total.toString()),
    totalPages: parseInt(paginationData.last_page.toString()),
    page: parseInt(paginationData.current_page.toString()),
    limit: parseInt(paginationData.per_page.toString()),
    docs: paginationData.data.map(doc => mappingFunc(doc)),
  };
};

export const mappingBoolean = (
  status: number | boolean,
  type: 'boolean-to-number' | 'number-to-boolean' = 'number-to-boolean'
): boolean | number => {
  if (type === 'boolean-to-number') {
    if (status === true) {
      return 1;
    }

    return 0;
  }

  if (status === 1) {
    return true;
  }

  return false;
};

export const parseToNumber = (
  input: string | number | null | undefined
): string => {
  try {
    if (input) {
      return parseInt(input.toString()).toString();
    }

    if (input?.toString() === '0') {
      return '0';
    }

    return '';
  } catch (error) {
    return '';
  }
};
