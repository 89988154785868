import { paginationConfig } from 'app/configs';
import {
  IPaginationQuery,
  IPaginationResponse,
  IPaginationServerResponse,
} from 'app/types/pagination';
import {
  EReportType,
  EReportTypeServerResponse,
  IReportParam,
} from 'app/types/report';
import { ISelectOption } from 'app/types/shared';
import {
  IStationConfigParam,
  IStationConfigParamServerResponse,
} from 'app/types/station';
import { LanguageTranslateText } from 'app/utils/enum';
import { envConfig } from 'configs/envConfig';
import moment from 'moment';
import queryString from 'query-string';
import { apiWrapper } from './axiosClient';
import { mappingServerToClientStationConfigParamStatus } from './stationAPI';

const { DEFAULT_PAGE, DEFAULT_LIMIT } = paginationConfig;
const { baseURL } = envConfig;

export type TFetchReportResponse = IPaginationResponse<IReportParam>;

const fetchReportTable = async (
  id: number,
  query: {
    startTime: string;
    endTime: string;
    reportType: EReportType;
    paramKey: string[];
    inValidData: boolean;
  } & IPaginationQuery
): Promise<TFetchReportResponse> => {
  const {
    startTime,
    endTime,
    reportType,
    paramKey,
    inValidData,
    page = DEFAULT_PAGE,
    limit = DEFAULT_LIMIT,
  } = query;

  const queryObject = {
    station_id: id,
    start_time: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
    end_time: moment(endTime).format('YYYY-MM-DD HH:mm:ss'),
    report_type: mappingClientToServerReportType(reportType),
    fume_codes: paramKey.map(prKey => prKey.toLowerCase()),
    invalid_data: inValidData ? 1 : 0,
    page: page,
    per_page: limit,
  };
  // const fakeUrl = `${baseURL}/api/stations/report?station_id=22&start_time=2021-07-27 00:00:00&end_time=2021-07-28 01:23:40&report_type=1&fume_codes=dust,flow&invalid_data=0&page=1&per_page=50`;

  const url = queryString.stringifyUrl(
    {
      url: '/api/stations/report',
      query: queryObject,
    },
    { arrayFormat: 'comma' }
  );

  const data = await apiWrapper.get<IPaginationServerResponse<string[]>>(url);
  const result = mappingReportDataForTable(data, paramKey);

  return result;
};

const fetchReportChart = async (
  id: number,
  query: {
    startTime: string;
    endTime: string;
    reportType: EReportType;
    paramKey: string[];
    inValidData: boolean;
  } & IPaginationQuery
): Promise<IStationConfigParam[]> => {
  const {
    startTime,
    endTime,
    reportType,
    paramKey,
    inValidData,
    page = DEFAULT_PAGE,
    limit = DEFAULT_LIMIT,
  } = query;

  const queryObject = {
    station_id: id,
    start_time: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
    end_time: moment(endTime).format('YYYY-MM-DD HH:mm:ss'),
    report_type: mappingClientToServerReportType(reportType),
    fume_codes: paramKey.map(prKey => prKey.toLowerCase()),
    invalid_data: inValidData ? 1 : 0,
    page: page,
    per_page: limit,
  };
  const url = queryString.stringifyUrl(
    {
      url: '/api/stations/chart/report',
      query: queryObject,
    },
    { arrayFormat: 'comma' }
  );

  const data = await apiWrapper.get<IStationConfigParamServerResponse[]>(url);
  const result = mappingReportDataForChart(data);

  return result;
};

const exportReportData = async (
  id: number,
  query: {
    startTime: string;
    endTime: string;
    reportType: EReportType;
    paramKey: string[];
    inValidData: boolean;
  }
): Promise<string> => {
  const { startTime, endTime, reportType, paramKey, inValidData } = query;

  const queryObject = {
    station_id: id,
    start_time: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
    end_time: moment(endTime).format('YYYY-MM-DD HH:mm:ss'),
    report_type: mappingClientToServerReportType(reportType),
    fume_codes: paramKey.map(prKey => prKey.toLowerCase()),
    invalid_data: inValidData ? 1 : 0,
  };
  const url = queryString.stringifyUrl(
    {
      url: '/api/stations/export',
      query: queryObject,
    },
    { arrayFormat: 'comma' }
  );

  const data = await apiWrapper.get<string>(url);
  const fullDownloadFilePath = `${baseURL}${data}`;

  return fullDownloadFilePath;
};

export const mappingClientToServerReportType = (
  type: EReportType
): EReportTypeServerResponse => {
  switch (type) {
    case EReportType.ONE_MINUTE:
      return EReportTypeServerResponse.ONE_MINUTE;
    case EReportType.FIVE_MINUTE:
      return EReportTypeServerResponse.FIVE_MINUTE;
    case EReportType.THIRTY_MINUTE:
      return EReportTypeServerResponse.THIRTY_MINUTE;
    case EReportType.HOUR:
      return EReportTypeServerResponse.HOUR;
    case EReportType.DAY:
      return EReportTypeServerResponse.DATE;
    case EReportType.MONTH:
      return EReportTypeServerResponse.MONTH;
  }
};

const mappingReportDataForTable = (
  paginateReport: IPaginationServerResponse<string[]>,
  paramCode: string[]
): TFetchReportResponse => {
  let result: IReportParam[] = [];
  const [, ...docs] = paginateReport.data;

  docs.forEach(doc => {
    let item: {
      time: Date;
      [key: string]: string | Date;
    } = {
      time: new Date(doc[0]),
    };
    paramCode.forEach((keyItem, index) => {
      item[keyItem] = doc[index + 1];
    });
    result = [...result, item];
  });

  return {
    totalDocs: parseInt(paginateReport.total.toString()),
    totalPages: parseInt(paginateReport.last_page.toString()),
    page: parseInt(paginateReport.current_page.toString()),
    limit: parseInt(paginateReport.per_page.toString()),
    docs: result,
  };
};

const mappingReportDataForChart = (
  configParam: IStationConfigParamServerResponse[]
): IStationConfigParam[] => {
  const result: IStationConfigParam[] = configParam.map(param => {
    return {
      id: param.id,
      stationId: param.station_id,
      name: param.fume_name,
      code: param.fume_code,
      unit: param.fume_unit,
      status: mappingServerToClientStationConfigParamStatus(param.status),
      standard: null,
      standardMin: param.standard_limit_min,
      standardMax: param.standard_limit_max,
      national: null,
      nationalMin: param.national_limit_min,
      nationalMax: param.national_limit_max,
      chartMin: param.limit_chart_min,
      chartMax: param.limit_chart_max,
      warningMin: param.warning_limit_min,
      warningMax: param.warning_limit_max,
      value: null,
      stationCode: param.station_code,
      trackings: param.data.map(dataItem => ({
        time: dataItem.time,
        value: dataItem.value,
      })),
    };
  });

  return result;
};

export const getReportOptions = (
  stationFetchInterval: number
): ISelectOption<EReportType>[] => {
  let defaultResult: ISelectOption<EReportType>[] = [
    {
      key: EReportType.ONE_MINUTE,
      label: LanguageTranslateText.TEXT_REPORT_TYPE_ONE_MINUTE,
      value: EReportType.ONE_MINUTE,
    },
    {
      key: EReportType.FIVE_MINUTE,
      label: LanguageTranslateText.TEXT_REPORT_TYPE_FIVE_MINUTE,
      value: EReportType.FIVE_MINUTE,
    },
    // {
    //   key: EReportType.THIRTY_MINUTE,
    //   label: LanguageTranslateText.TEXT_REPORT_TYPE_THIRTY_MINUTE,
    //   value: EReportType.THIRTY_MINUTE,
    // },
    {
      key: EReportType.HOUR,
      label: LanguageTranslateText.TEXT_REPORT_TYPE_ONE_HOUR,
      value: EReportType.HOUR,
    },
    {
      key: EReportType.DAY,
      label: LanguageTranslateText.TEXT_REPORT_TYPE_ONE_DAY,
      value: EReportType.DAY,
    },
    // {
    //   key: EReportType.MONTH,
    //   label: LanguageTranslateText.TEXT_REPORT_TYPE_ONE_MONTH,
    //   value: EReportType.MONTH,
    // },
  ];

  if (stationFetchInterval <= 1) {
    // less than one minute
    return defaultResult;
  }

  if (stationFetchInterval <= 5) {
    // less than five minutes
    return defaultResult.filter(r => r.key !== EReportType.ONE_MINUTE);
  }

  // if (stationFetchInterval <= 30) {
  //   // less than thirty minutes
  //   return defaultResult.filter(r =>
  //     [EReportType.ONE_MINUTE, EReportType.FIVE_MINUTE].some(t => r.key !== t)
  //   );
  // }

  if (stationFetchInterval <= 1 * 60) {
    // less than one hour
    return defaultResult.filter(r =>
      [
        EReportType.ONE_MINUTE,
        EReportType.FIVE_MINUTE,
        EReportType.THIRTY_MINUTE,
      ].some(t => r.key !== t)
    );
  }

  if (stationFetchInterval <= 1 * 24 * 60) {
    // less than one day
    return defaultResult.filter(r =>
      [
        EReportType.ONE_MINUTE,
        EReportType.FIVE_MINUTE,
        EReportType.THIRTY_MINUTE,
        EReportType.HOUR,
      ].some(t => r.key !== t)
    );
  }

  // if (stationFetchInterval <= 1 * 30 * 24 * 60) {
  //   // less than one month
  //   return defaultResult.filter(r =>
  //     [
  //       EReportType.ONE_MINUTE,
  //       EReportType.FIVE_MINUTE,
  //       EReportType.THIRTY_MINUTE,
  //       EReportType.HOUR,
  //       EReportType.DAY,
  //     ].some(t => r.key !== t)
  //   );
  // }

  return [];
};

const reportAPI = { fetchReportTable, fetchReportChart, exportReportData };

export default reportAPI;
