import { CloseOutlined } from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  styled,
  SwipeableDrawer,
  SwipeableDrawerProps,
} from '@mui/material';
import { sidebarConfig } from 'app/configs';
import LoadingForm from 'app/layout/form/LoadingForm';
import { useContext } from 'react';
import { EDrawerType } from '../enum';
import DrawerContext from '../index';

const { SIDEBAR_WIDTH } = sidebarConfig;

interface Props {
  drawerType: EDrawerType;
  drawerWidth?: number;
  position?: SwipeableDrawerProps['anchor'];
  fullScreen?: boolean;
  closeable?: boolean;
  header?: JSX.Element;
  loading?: boolean;
}

const DrawerHeader = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
  position: 'relative',
}));

const DrawerWrapper: React.FC<Props> = ({
  children,
  drawerWidth,
  drawerType,
  position = 'left',
  fullScreen = false,
  closeable = true,
  header,
  loading = false,
  ...rest
}) => {
  const { closeDrawer, checkOpen } = useContext(DrawerContext);
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const status = checkOpen('drawer', drawerType);

  const handleClose = () => {
    closeDrawer();
  };

  return (
    <SwipeableDrawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      open={status}
      onClose={() => closeDrawer()}
      onOpen={() => {}}
      PaperProps={{
        sx: {
          minWidth: '15em',
          width: {
            xs: '100vw',
            md: fullScreen ? '100vw' : drawerWidth || SIDEBAR_WIDTH,
          },
        },
      }}
      anchor={position}
      {...rest}
    >
      {loading && <LoadingForm variant='drawer' />}
      <DrawerHeader>
        {closeable && (
          <IconButton
            sx={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              ...(position === 'left' && {
                right: 0,
              }),
              ...(position === 'right' && {
                left: 0,
              }),
            }}
            onClick={handleClose}
          >
            <CloseOutlined />
          </IconButton>
        )}
        {header}
      </DrawerHeader>
      <Divider />
      <Box sx={{ width: '100%' }}>{children}</Box>
    </SwipeableDrawer>
  );
};

export default DrawerWrapper;
