import { paginationConfig } from 'app/configs';
import { ICompanyServerResponse } from 'app/types/company';
import {
  IPaginationQuery,
  IPaginationQueryServer,
  IPaginationResponse,
  IPaginationServerResponse,
} from 'app/types/pagination';
import { EUserRole, IUser, IUserServerResponse } from 'app/types/user';
import { isEmpty } from 'app/utils/helper';
import { envConfig } from 'configs/envConfig';
import moment from 'moment';
import queryString from 'query-string';
import { apiWrapper } from './axiosClient';

export type TFetchUsersArgs = IPaginationQuery & { companyId: number };
export type TFetchUsersRes = IPaginationResponse<IUser>;
export type TCreateUserArgs = {
  name: string;
  email: string;
  role: EUserRole;
  phoneNumber: string;
  birthday: string;
  username: string;
  avatar: string | any;
  password: string;
  companyId: number;
  stationIds: number[];
};
export type TCreateUserRes = string;
export type TUpdateUserArgs = {
  id: number;
  name: string;
  email: string;
  role: EUserRole;
  phoneNumber: string;
  birthday?: string;
  username: string;
  avatar: string | any;
  password?: string;
  oldPassword?: string;
  stationIds: number[];
};
export type TUpdateUserRes = string;
export type TDeleteUserArgs = {
  id: number;
};
export type TDeleteUserRes = string;

export type TFetchOwnersArgs = IPaginationQuery;
export type TFetchOwnersRes = IPaginationResponse<IUser>;
export type TCreateOwnerArgs = {
  name: string;
  email: string;
  role: EUserRole;
  phoneNumber: string | null;
  birthday: string | null;
  username: string | null;
  avatar: string | null | any;
  password: string;
};
export type TCreateOwnerRes = string;
export type TUpdateOwnerArgs = {
  id: number;
  name: string;
  email: string;
  role: EUserRole;
  phoneNumber: string | null;
  birthday?: string | null;
  username: string | null;
  avatar: string | null | any;
  password?: string | null;
  oldPassword?: string | null;
};
export type TUpdateOwnerRes = string;
export type TDeleteOwnerArgs = {
  id: number;
};
export type TDeleteOwnerRes = string;

type ApiFetchUsersArgs = IPaginationQueryServer & { company_id: number };
type ApiFetchUsersRes = IPaginationServerResponse<IUserServerResponse>;
type ApiCreateUserArgs = {
  name: string;
  email: string;
  role: EUserRole;
  telephone: string | null;
  birthday: string | null;
  user_name: string | null;
  avatar: string | null | any;
  password: string;
  company_id: number;
  station_ids: number[];
};
type ApiCreateUserRes = string;
type ApiUpdateUserArgs = {
  id: number;
  name: string;
  email: string;
  role: EUserRole;
  telephone: string | null;
  birthday?: string | null;
  user_name: string | null;
  avatar: string | null | any;
  password?: string | null;
  old_password?: string | null;
  station_ids: number[];
};
type ApiUpdateUserRes = string;
type ApiDeleteUserArgs = {
  user_id: number;
};
type APiDeleteUserRes = string;

type ApiFetchOwnersArgs = IPaginationQueryServer;
type ApiFetchOwnersRes = IPaginationServerResponse<IUserServerResponse>;
type ApiCreateOwnerArgs = {
  name: string;
  email: string;
  role: EUserRole;
  telephone: string | null;
  birthday: string | null;
  user_name: string | null;
  avatar: string | null | any;
  password: string;
};
type ApiCreateOwnerRes = string;
type ApiUpdateOwnerArgs = {
  id: number;
  name: string;
  email: string;
  role: EUserRole;
  telephone: string | null;
  birthday: string | null;
  user_name: string | null;
  avatar: string | null | any;
  password?: string | null;
  old_password?: string | null;
};
type ApiUpdateOwnerRes = string;
type ApiDeleteOwnerArgs = {
  id: number;
};
type APiDeleteOwnerRes = string;

const { DEFAULT_PAGE, DEFAULT_LIMIT } = paginationConfig;
const { baseURL } = envConfig;

const fetchUsers = async (query: TFetchUsersArgs): Promise<TFetchUsersRes> => {
  const queryObject: ApiFetchUsersArgs = {
    page: query.page || DEFAULT_PAGE,
    per_page: query.limit || DEFAULT_LIMIT,
    company_id: query.companyId,
  };
  const url = queryString.stringifyUrl({
    url: '/api/admin/users',
    query: queryObject as any,
  });
  const data = await apiWrapper.get<ApiFetchUsersRes>(url);
  const result: TFetchUsersRes = {
    totalDocs: parseInt(data.total.toString()),
    totalPages: parseInt(data.last_page.toString()),
    page: parseInt(data.current_page.toString()),
    limit: parseInt(data.per_page.toString()),
    docs: data.data.map(doc => mappingServerUser(doc, null)!),
  };

  return result;
};
const createUser = async (param: TCreateUserArgs): Promise<TCreateUserRes> => {
  const data = await apiWrapper.post<ApiCreateUserArgs, ApiCreateUserRes>(
    '/api/admin/user',
    {
      name: param.name,
      email: param.email,
      role: param.role,
      telephone: param.phoneNumber,
      birthday: param.birthday
        ? moment(param.birthday).format('YYYY-MM-DD')
        : '',
      user_name: param.username,
      avatar: param.avatar ? param.avatar : '',
      password: param.password ? param.password : '',
      company_id: param.companyId,
      station_ids: param.stationIds,
    },
    {
      contentType: 'multipart/form-data',
    }
  );

  return data;
};
const updateUser = async (param: TUpdateUserArgs): Promise<TUpdateUserRes> => {
  const data = await apiWrapper.post<ApiUpdateUserArgs, ApiUpdateUserRes>(
    '/api/admin/user/update',
    {
      id: param.id,
      name: param.name,
      email: param.email,
      role: param.role,
      telephone: param.phoneNumber,
      birthday: param.birthday
        ? moment(param.birthday).format('YYYY-MM-DD')
        : '',
      user_name: param.username,
      avatar: param.avatar ? param.avatar : '',
      password: param.password ? param.password : '',
      old_password: param.oldPassword ? param.oldPassword : '',
      station_ids: param.stationIds,
    },
    {
      contentType: 'multipart/form-data',
    }
  );

  return data;
};
const deleteUser = async (param: TDeleteUserArgs): Promise<TDeleteUserRes> => {
  const data = await apiWrapper.post<ApiDeleteUserArgs, APiDeleteUserRes>(
    '/api/admin/user/delete',
    {
      user_id: param.id,
    },
    {
      contentType: 'multipart/form-data',
    }
  );

  return data;
};

const fetchOwners = async (
  query: TFetchOwnersArgs
): Promise<TFetchOwnersRes> => {
  const queryObject: ApiFetchOwnersArgs = {
    page: query.page || DEFAULT_PAGE,
    per_page: query.limit || DEFAULT_LIMIT,
  };
  const url = queryString.stringifyUrl({
    url: '/api/owner',
    query: queryObject as any,
  });
  const data = await apiWrapper.get<ApiFetchOwnersRes>(url);
  const result: TFetchUsersRes = {
    totalDocs: parseInt(data.total.toString()),
    totalPages: parseInt(data.last_page.toString()),
    page: parseInt(data.current_page.toString()),
    limit: parseInt(data.per_page.toString()),
    docs: data.data.map(doc => {
      const company: ICompanyServerResponse | null =
        doc.company_id && doc.company_name && doc.company_logo
          ? {
              id: doc.company_id,
              company_name: doc.company_name,
              slogan: doc.company_slogan || '',
              logo: doc.company_logo,
              created_at: '',
              updated_at: '',
              deleted_at: null,
            }
          : null;

      return mappingServerUser(doc, company)!;
    }),
  };

  return result;
};

const createOwner = async (
  param: TCreateOwnerArgs
): Promise<TCreateOwnerRes> => {
  const data = await apiWrapper.post<ApiCreateOwnerArgs, ApiCreateOwnerRes>(
    '/api/owner',
    {
      name: param.name,
      email: param.email,
      role: param.role,
      telephone: param.phoneNumber,
      birthday: param.birthday
        ? moment(param.birthday).format('YYYY-MM-DD')
        : '',
      user_name: param.username,
      avatar: param.avatar ? param.avatar : '',
      password: param.password,
    },
    {
      contentType: 'multipart/form-data',
    }
  );

  return data;
};
const updateOwner = async (
  param: TUpdateOwnerArgs
): Promise<TUpdateOwnerRes> => {
  const data = await apiWrapper.post<ApiUpdateOwnerArgs, ApiUpdateOwnerRes>(
    '/api/owner/update',
    {
      id: param.id,
      name: param.name,
      email: param.email,
      role: param.role,
      telephone: param.phoneNumber,
      birthday: param.birthday
        ? moment(param.birthday).format('YYYY-MM-DD')
        : '',
      user_name: param.username,
      avatar: param.avatar ? param.avatar : '',
      password: param.password ? param.password : '',
      old_password: param.oldPassword ? param.oldPassword : '',
    },
    {
      contentType: 'multipart/form-data',
    }
  );

  return data;
};
const deleteOwner = async (
  param: TDeleteOwnerArgs
): Promise<TDeleteOwnerRes> => {
  const data = await apiWrapper.post<ApiDeleteOwnerArgs, APiDeleteOwnerRes>(
    '/api/owner/delete',
    {
      id: param.id,
    },
    {
      contentType: 'multipart/form-data',
    }
  );

  return data;
};

export const mappingUserRole = (serverRole: string): EUserRole | null => {
  switch (serverRole) {
    case 'admin':
      return EUserRole.ADMIN;
    case 'user':
      return EUserRole.USER;
    case 'owner':
      return EUserRole.OWNER;
    default:
      return null;
  }
};

export const mappingServerUser = (
  user: IUserServerResponse,
  company: ICompanyServerResponse | null
): IUser | null => {
  const userRole = mappingUserRole(user.role);
  if (!userRole) {
    return null;
  }

  return {
    id: user.id,
    name: user.name,
    email: user.email ? user.email : '',
    role: userRole,
    phoneNumber: user.telephone ? user.telephone : '',
    username: user.user_name ? user.user_name : '',
    avatar: user.avatar || '',
    createdAt: new Date(user.created_at),
    updatedAt: new Date(user.updated_at),
    deletedAt: new Date(user.deleted_at),
    birthday: user.birthday ? user.birthday : '',
    company: company
      ? {
          id: company.id,
          name: company.company_name,
          slogan: company.slogan,
          logo: `${baseURL}${company.logo}`,
          address: isEmpty(company.address) ? '' : company.address,
          postalCode: isEmpty(company.postal_code) ? '' : company.postal_code,
          email: isEmpty(company.email) ? '' : company.email,
          website: isEmpty(company.website) ? '' : company.website,
          telephone: isEmpty(company.telephone) ? '' : company.telephone,
          createdAt: new Date(company.created_at),
          updatedAt: new Date(company.updated_at),
        }
      : null,
    stationIds: user?.station_ids || [],
  };
};

const userAPI = {
  fetchUsers,
  createUser,
  updateUser,
  deleteUser,
  fetchOwners,
  createOwner,
  updateOwner,
  deleteOwner,
};

export default userAPI;
