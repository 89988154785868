import { NotificationPayload } from '@firebase/messaging';
import {
  CheckCircleOutlineOutlined,
  ErrorOutlineOutlined,
  WarningAmberOutlined,
} from '@mui/icons-material';
import { ChipProps } from '@mui/material';
import notificationsAPI, {
  TConfigNotificationsArgs,
  TConfigNotificationsRes,
  TDeleteNotificationTokenKeyArgs,
  TDeleteNotificationTokenKeyRes,
  TFetchAlertNotificationConfigurationsArgs,
  TFetchAlertNotificationConfigurationsRes,
  TFetchNotificationsArgs,
  TFetchNotificationsRes,
  TStoreNotificationTokenKeyArgs,
  TStoreNotificationTokenKeyRes,
} from 'app/api/notificationsAPI';
import {
  EAlertNotificationTypeServerResponse,
  ENotificationStatus,
} from 'app/types/notification';
import { LanguageTranslateText } from 'app/utils/enum';
import { useMutation } from 'react-query';
import useSound from 'use-sound';
import useAlert from './useAlert';
import useAuth from './useAuth';

function useNotifications() {
  const [playError] = useSound('/sounds/error-01.mp3', {
    volume: 0.25,
  });
  const { alertSuccess, alertError, alertWarning } = useAlert();
  const { user } = useAuth();
  const { mutateAsync: fetchNotifications } = useMutation<
    TFetchNotificationsRes,
    any,
    TFetchNotificationsArgs
  >(params => {
    return notificationsAPI.fetchNotifications(params);
  });

  const { mutateAsync: fetchAlertNotificationConfigurations } = useMutation<
    TFetchAlertNotificationConfigurationsRes,
    any,
    TFetchAlertNotificationConfigurationsArgs
  >(params => {
    return notificationsAPI.fetchAlertNotificationConfigurations(params);
  });

  const { mutateAsync: configNotifications } = useMutation<
    TConfigNotificationsRes,
    any,
    TConfigNotificationsArgs
  >(params => {
    return notificationsAPI.configNotifications(params);
  });
  const { mutateAsync: storeNotificationKey } = useMutation<
    TStoreNotificationTokenKeyRes,
    any,
    TStoreNotificationTokenKeyArgs
  >(params => {
    return notificationsAPI.storeNotificationTokenKey(params);
  });
  const { mutateAsync: deleteNotificationKey } = useMutation<
    TDeleteNotificationTokenKeyRes,
    any,
    TDeleteNotificationTokenKeyArgs
  >(params => {
    return notificationsAPI.deleteNotificationTokenKey(params);
  });

  const handleNotificationAlert = (data: NotificationPayload | undefined) => {
    if (data && data.body && typeof data.body === 'string') {
      const body: {
        message: string;
        alert_type: EAlertNotificationTypeServerResponse;
        user_ids?: number[];
      } = JSON.parse(data.body);
      const { message, alert_type, user_ids } = body;
      if (user && user_ids && user_ids.includes(user.id)) {
        switch (alert_type) {
          case EAlertNotificationTypeServerResponse.NORMAL:
            alertSuccess(message);
            break;
          case EAlertNotificationTypeServerResponse.DISCONNECTED:
            playError();
            alertWarning(message);
            break;
          case EAlertNotificationTypeServerResponse.STATUS_ERROR:
          case EAlertNotificationTypeServerResponse.CROSS_NATIONAL_LINE_ERROR:
          case EAlertNotificationTypeServerResponse.CROSS_WARNING_LINE_ERROR:
          default:
            playError();
            alertError(message);
            break;
        }
      }
    }
  };

  const getNotificationStatusConfig = (
    status: ENotificationStatus
  ): { label: string; color: ChipProps['color']; icon: JSX.Element } => {
    switch (status) {
      case ENotificationStatus.PENDING:
        return {
          label: LanguageTranslateText.TEXT_NOTIFICATIONS_STATUS_PENDING,
          color: 'warning',
          icon: <WarningAmberOutlined color='warning' />,
        };
      case ENotificationStatus.FAILED:
        return {
          label: LanguageTranslateText.TEXT_NOTIFICATIONS_STATUS_FAILED,
          color: 'error',
          icon: <ErrorOutlineOutlined color='error' />,
        };
      case ENotificationStatus.SUCCEEDED:
        return {
          label: LanguageTranslateText.TEXT_NOTIFICATIONS_STATUS_SUCCEEDED,
          color: 'success',
          icon: <CheckCircleOutlineOutlined color='success' />,
        };
    }
  };

  return {
    fetchNotifications,
    configNotifications,
    fetchAlertNotificationConfigurations,
    getNotificationStatusConfig,
    storeNotificationKey,
    deleteNotificationKey,
    handleNotificationAlert,
    playError,
  };
}

export default useNotifications;
