import { paginationConfig } from 'app/configs';
import {
  IPaginationQuery,
  IPaginationResponse,
  IPaginationServerResponse,
} from 'app/types/pagination';
import {
  EStationConfigParamsStatus,
  EStationConfigParamStatusServerResponse,
  EStationDataType,
  EStationDataTypeServerResponse,
  EStationStatus,
  EStationStatusServerResponse,
  EStationStructureType,
  EStationStructureTypeServerResponse,
  IStation,
  IStationConfigParam,
  IStationServerResponse,
} from 'app/types/station';
import { LanguageTranslateText } from 'app/utils/enum';
import { isEmpty } from 'app/utils/helper';
import Decimal from 'decimal.js';
import moment from 'moment';
import queryString from 'query-string';
import { apiWrapper } from './axiosClient';
import { getStationTypeKey } from './configSystemAPI';

export type TSingleStationArgs = string;
export type TMultipleStationArgs = {
  id: string;
  stationName: string;
  stationCode: string;
}[];
export type TConfigParamArgs = {
  id: string;
  name: string;
  code: string;
  unit: string;
  limitChartMin: number | string;
  limitChartMax: number | string;
  warningLimitMin: number | string;
  warningLimitMax: number | string;
  nationalLimitMin: number | string;
  nationalLimitMax: number | string;
  stationCode: string | null;
};
export type TCreateStationArgs = {
  name: TSingleStationArgs | TMultipleStationArgs;
  path: string;
  color: string;
  stationType: number | string;
  structureType: EStationStructureType;
  dataType: EStationDataType;
  timeSync: number;
  company: number;
  configParams: TConfigParamArgs[];
};
export type TFetchStationsArgs = {
  search?: string;
  status?: EStationStatus;
  type?: string;
  ids?: (string | number)[];
} & IPaginationQuery;
export type TCreateStationRes = string;
export type TUpdateStationArgs = {
  stationId: number;
  stationType: number | string;
  name: string;
  configParams: TConfigParamArgs[];
};
export type TUpdateStationRes = string;
export type TFetchStationsSummaryArgs = {
  ids: string[];
  search?: string;
};
export type TFetchStationsSummaryRes = IStation[];
export type TFetchStationRealtimeArgs = {
  id: number;
  latestTime?: string | Date | null;
};
export type TFetchStationRealtimeRes = IStation;
export type TDeleteStationArgs = { id: number };
export type TDeleteStationRes = string;
export type TFetchStationsByStatusArgs = {};
export type TFetchStationsByStatusRes = {
  status: EStationStatus;
  numberOfStation: number;
}[];

type ApiCreateStationArgs = {
  name:
    | string
    | {
        station_name: string;
        station_code: string;
      }[];
  path: string;
  station_type_id: number;
  structure_type: number;
  data_type: number;
  time_sync: number;
  company_id: number;
  fume_setting: {
    fume_name: string;
    fume_code: string;
    fume_unit: string;
    limit_chart_min: number | null | string;
    limit_chart_max: number | null | string;
    warning_limit_min: number | null | string;
    warning_limit_max: number | null | string;
    national_limit_min: number | null | string;
    national_limit_max: number | null | string;
    station_code: string | null;
  }[];
};
type ApiCreateStationRes = string;
type ApiUpdateStationArgs = {
  station_id: number;
  station_type_id: number | string;
  name: string | null;
  fume_setting: {
    [key: string]:
      | {
          fume_name: string;
          fume_unit: string;
          limit_chart_min: number | string;
          limit_chart_max: number | string;
          warning_limit_min: number | string;
          warning_limit_max: number | string;
          national_limit_min: number | string;
          national_limit_max: number | string;
        }
      | number
      | null
      | undefined
      | string;
  };
};
type ApiUpdateStationRes = string;
type ApiFetchStationsSummaryArgs = {
  ids: string[];
  search?: string;
};

type ApiFetchStationsSummaryRes = {
  id: number;
  name: string;
  company_id: number;
  station_type_id: number;
  status: EStationStatusServerResponse;
  path: string;
  color: string;
  server_path: string;
  structure_type: EStationStructureTypeServerResponse;
  data_type: EStationDataTypeServerResponse;
  time_sync: number;
  station_code: string | null;
  last_time_sync_data: string;
  last_time_have_data: string | null;
  start_sync_data_time: string;
  sync_old_data_status: EStationStatusServerResponse;
  current_time_sync_old_data: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  fume_info: {
    [key: string]: {
      id: number;
      station_id: number;
      fume_column: string;
      fume_name: string;
      fume_code: string;
      fume_unit: string;
      status: EStationConfigParamStatusServerResponse;
      standard_limit_min: number | null;
      standard_limit_max: number | null;
      national_limit_min: number | null;
      national_limit_max: number | null;
      limit_chart_min: number | null;
      limit_chart_max: number | null;
      warning_limit_min: number | null;
      warning_limit_max: number | null;
      created_at: string;
      updated_at: string;
      deleted_at: string | null;
      value: string;
      station_code: string | null;
    };
  };
}[];
type ApiFetchStationRealtimeArgs = {
  station_id: number;
  latest_time?: string | null;
};
type ApiFetchStationRealtimeRes = {
  id: number;
  name: string;
  company_id: number;
  station_type_id: number;
  status: EStationStatusServerResponse;
  path: string;
  server_path: string;
  structure_type: EStationStructureTypeServerResponse;
  data_type: EStationDataTypeServerResponse;
  time_sync: number;
  station_code: string;
  last_time_sync_data: string;
  last_time_have_data: string;
  start_sync_data_time: string;
  sync_old_data_status: EStationStatusServerResponse;
  current_time_sync_old_data: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  data_chart: {
    id: number;
    station_id: number;
    fume_column: string;
    fume_name: string;
    fume_code: string;
    fume_unit: string;
    status: EStationConfigParamStatusServerResponse;
    limit_chart_min: number | null;
    limit_chart_max: number | null;
    warning_limit_min: number | null;
    warning_limit_max: number | null;
    national_limit_min: number | null;
    national_limit_max: number | null;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    data: {
      time: string;
      value: string;
    }[];
  }[];
};
type ApiDeleteStationArgs = { id: number };
type ApiDeleteStationRes = string;
type ApiFetchStationsByStatusArgs = {};
type ApiFetchStationsByStatusRes = {
  [key: string]: number;
};

const { DEFAULT_PAGE, DEFAULT_LIMIT } = paginationConfig;

const fetchStations = async (
  query: TFetchStationsArgs
): Promise<IStation[]> => {
  const queryObject = {
    name: query.search,
    station_type: query.type,
    status: mappingClientStationStatus(query.status),
  };
  const url = queryString.stringifyUrl(
    {
      url: '/api/stations',
      query: queryObject,
    },
    { arrayFormat: 'comma' }
  );

  const data = await apiWrapper.get<IStationServerResponse[]>(url);
  const stations: IStation[] = data.map(doc => {
    return mappingServerStation(doc);
  });

  return stations;
};

const fetchStationsWithPagination = async (
  query: TFetchStationsArgs
): Promise<IPaginationResponse<IStation>> => {
  const queryObject = {
    page: query.page || DEFAULT_PAGE,
    per_page: query.limit || DEFAULT_LIMIT,
    name: query.search,
    station_type: query.type,
    ids: query.ids ? query.ids.map(id => id.toString()) : undefined,
    status: mappingClientStationStatus(query.status),
  };
  const url = queryString.stringifyUrl(
    {
      url: '/api/stations',
      query: queryObject,
    },
    { arrayFormat: 'comma' }
  );
  const serverData = await apiWrapper.get<
    IPaginationServerResponse<IStationServerResponse>
  >(url);
  const result: IPaginationResponse<IStation> = {
    totalDocs: parseInt(serverData.total.toString()),
    totalPages: parseInt(serverData.last_page.toString()),
    page: parseInt(serverData.current_page.toString()),
    limit: parseInt(serverData.per_page.toString()),
    docs: serverData.data.map(doc => mappingServerStation(doc)),
  };

  return result;
};

const fetchStationsSummary = async (
  param: TFetchStationsSummaryArgs
): Promise<TFetchStationsSummaryRes> => {
  const queryObject: ApiFetchStationsSummaryArgs = {
    ids: param.ids,
    search: param.search,
  };
  const url = queryString.stringifyUrl(
    {
      url: '/api/stations/summary',
      query: queryObject,
    },
    { arrayFormat: 'comma' }
  );
  const data = await apiWrapper.get<ApiFetchStationsSummaryRes>(url);
  const stations: IStation[] = data.map(item => {
    let configParams: IStationConfigParam[] = [];
    const serverFumeInfo = item.fume_info;

    for (const key in serverFumeInfo) {
      if (Object.prototype.hasOwnProperty.call(serverFumeInfo, key)) {
        const element = serverFumeInfo[key];
        configParams = [
          ...configParams,
          {
            id: element.id,
            stationId: element.station_id,
            name: element.fume_name,
            code: element.fume_code,
            unit: element.fume_unit,
            status: mappingServerToClientStationConfigParamStatus(
              element.status
            ),
            standardMin: element.standard_limit_min,
            standardMax: element.standard_limit_max,
            nationalMin: element.national_limit_min,
            nationalMax: element.national_limit_max,
            chartMin: element.limit_chart_min,
            chartMax: element.limit_chart_max,
            warningMin: element.warning_limit_min,
            warningMax: element.warning_limit_max,
            value: element.value ? new Decimal(element.value).toNumber() : null,
            stationCode: element.station_code,
            trackings: [],
          },
        ];
      }
    }
    const station: IStation = {
      id: item.id,
      path: item.path,
      name: item.name,
      link: `/stations/${item.id}`,
      color: item.color,
      structureType: mappingServerStationStructureType(item.structure_type),
      status: mappingServerStationStatus(item.status),
      type: {
        id: item.station_type_id,
        name: '',
        key: getStationTypeKey(item.station_type_id),
        color: null,
      },
      dataType: mappingServerStationDataType(item.data_type),
      company: {
        id: item.company_id,
      },
      configParams,
      fetchInterval: item.time_sync, // server not response, this info is not importance
      lastTimeSyncData: new Date(item.last_time_sync_data),
      lastTimeHaveData: item.last_time_have_data
        ? new Date(item.last_time_have_data)
        : undefined,
      users: '',
    };

    return station;
  });

  return stations;
};

const fetchStationRealtime = async (
  param: TFetchStationRealtimeArgs
): Promise<TFetchStationRealtimeRes> => {
  const queryObject: ApiFetchStationRealtimeArgs = {
    station_id: param.id,
    latest_time: param.latestTime
      ? moment(param.latestTime).format('YYYY-MM-DD HH:mm:ss')
      : '',
  };
  const url = queryString.stringifyUrl(
    {
      url: '/api/stations/chart/real_time',
      query: queryObject,
    },
    { arrayFormat: 'comma' }
  );
  const data = await apiWrapper.get<ApiFetchStationRealtimeRes>(url);
  let configParams: IStationConfigParam[] = [];
  const serverFumeInfo = data.data_chart;

  for (const key in serverFumeInfo) {
    if (Object.prototype.hasOwnProperty.call(serverFumeInfo, key)) {
      const element = serverFumeInfo[key];
      configParams = [
        ...configParams,
        {
          id: element.id,
          stationId: element.station_id,
          name: element.fume_name,
          code: element.fume_code,
          unit: element.fume_unit,
          status: mappingServerToClientStationConfigParamStatus(element.status),
          standardMin: null,
          standardMax: null,
          nationalMin: element.national_limit_min,
          nationalMax: element.national_limit_max,
          chartMin: element.limit_chart_min,
          chartMax: element.limit_chart_max,
          warningMin: element.warning_limit_min,
          warningMax: element.warning_limit_max,
          value: null,
          stationCode: null,
          trackings: element.data.map(itemData => ({
            time: itemData.time,
            value: itemData.value,
          })),
        },
      ];
    }
  }
  const station: IStation = {
    id: data.id,
    path: data.path,
    name: data.name,
    link: `/stations/${data.id}`,
    color: null,
    structureType: mappingServerStationStructureType(data.structure_type),
    status: mappingServerStationStatus(data.status),
    type: {
      id: data.station_type_id,
      name: '',
      key: getStationTypeKey(data.station_type_id),
      color: null,
    },
    dataType: mappingServerStationDataType(data.data_type),
    company: {
      id: data.company_id,
    },
    configParams,
    fetchInterval: data.time_sync,
    users: '',
  };

  return station;
};

const createStation = async (args: TCreateStationArgs): Promise<string> => {
  const data = await apiWrapper.post<ApiCreateStationArgs, ApiCreateStationRes>(
    `/api/station/create`,
    {
      name: Array.isArray(args.name)
        ? args.name.map(it => ({
            station_name: it.stationName,
            station_code: it.stationCode,
          }))
        : args.name,
      path: args.path,
      station_type_id: parseInt(args.stationType.toString()),
      structure_type: mappingClientStationStructureType(args.structureType),
      data_type: mappingClientStationDataType(args.dataType),
      time_sync: parseInt(args.timeSync.toString()),
      company_id: parseInt(args.company.toString()),
      fume_setting: args.configParams.map(pr => {
        return {
          fume_name: pr.name,
          fume_code: pr.code,
          fume_unit: isEmpty(pr.unit) ? '' : pr.unit,
          limit_chart_min: isEmpty(pr.limitChartMin.toString())
            ? ''
            : new Decimal(pr.limitChartMin.toString()).toNumber(),
          limit_chart_max: isEmpty(pr.limitChartMax.toString())
            ? ''
            : new Decimal(pr.limitChartMax.toString()).toNumber(),
          warning_limit_min: isEmpty(pr.warningLimitMin.toString())
            ? ''
            : new Decimal(pr.warningLimitMin.toString()).toNumber(),
          warning_limit_max: isEmpty(pr.warningLimitMax.toString())
            ? ''
            : new Decimal(pr.warningLimitMax.toString()).toNumber(),
          national_limit_min: isEmpty(pr.nationalLimitMin.toString())
            ? ''
            : new Decimal(pr.nationalLimitMin.toString()).toNumber(),
          national_limit_max: isEmpty(pr.nationalLimitMax.toString())
            ? ''
            : new Decimal(pr.nationalLimitMax.toString()).toNumber(),
          station_code: pr.stationCode ? pr.stationCode : '',
        };
      }),
    },
    {
      contentType: 'application/json',
    }
  );

  return data;
};

const updateStation = async (args: TUpdateStationArgs): Promise<string> => {
  let body: ApiUpdateStationArgs = {
    station_id: args.stationId,
    station_type_id: args.stationType ? args.stationType : '',
    name: args.name ? args.name : '',
    fume_setting: {},
  };

  args.configParams.forEach(param => {
    body = {
      ...body,
      fume_setting: {
        ...body.fume_setting,
        [param.code]: {
          fume_name: param.name,
          fume_unit: isEmpty(param.unit) ? '' : param.unit,
          limit_chart_min: isEmpty(param.limitChartMin.toString())
            ? ''
            : new Decimal(param.limitChartMin.toString()).toNumber(),
          limit_chart_max: isEmpty(param.limitChartMax.toString())
            ? ''
            : new Decimal(param.limitChartMax.toString()).toNumber(),
          warning_limit_min: isEmpty(param.warningLimitMin.toString())
            ? ''
            : new Decimal(param.warningLimitMin.toString()).toNumber(),
          warning_limit_max: isEmpty(param.warningLimitMax.toString())
            ? ''
            : new Decimal(param.warningLimitMax.toString()).toNumber(),
          national_limit_min: isEmpty(param.nationalLimitMin.toString())
            ? ''
            : new Decimal(param.nationalLimitMin.toString()).toNumber(),
          national_limit_max: isEmpty(param.nationalLimitMax.toString())
            ? ''
            : new Decimal(param.nationalLimitMax.toString()).toNumber(),
        },
      },
    };
  });

  const data = await apiWrapper.post<ApiUpdateStationArgs, ApiUpdateStationRes>(
    '/api/station/update',
    {
      ...body,
    },
    {
      contentType: 'application/json',
    }
  );

  return data;
};

const deleteStation = async (
  args: TDeleteStationArgs
): Promise<TDeleteStationRes> => {
  const queryObject: ApiDeleteStationArgs = {
    id: args.id,
  };
  const url = queryString.stringifyUrl({
    url: '/api/station',
    query: queryObject,
  });
  const result = await apiWrapper._delete<any, ApiDeleteStationRes>(
    url,
    {},
    {}
  );

  return result;
};

const canCreateNewStation = async (): Promise<boolean> => {
  const result = await apiWrapper.get<number | string>(
    '/api/can_create_station'
  );
  if (result && parseInt(result.toString()) === 1) {
    return true;
  }

  return false;
};

const fetchStationsByStatus = async (
  query: TFetchStationsByStatusArgs
): Promise<TFetchStationsByStatusRes> => {
  let result: TFetchStationsByStatusRes = [];

  const queryObject: ApiFetchStationsByStatusArgs = { ...query };
  const url = queryString.stringifyUrl({
    url: '/api/stations/summary_status',
    query: queryObject,
  });

  const res = await apiWrapper.get<ApiFetchStationsByStatusRes>(url);
  for (const key in res) {
    if (Object.prototype.hasOwnProperty.call(res, key)) {
      const element = res[key];

      const serverStatus: EStationStatusServerResponse = parseInt(key);

      result = [
        ...result,
        {
          status: mappingServerStationStatus(serverStatus),
          numberOfStation: element,
        },
      ];
    }
  }

  return result;
};

export const mappingServerStation = (
  serverStation: IStationServerResponse
): IStation => {
  let configParams: IStationConfigParam[] = [];
  const serverFumeInfo = serverStation.fume_info;

  for (const key in serverFumeInfo) {
    if (Object.prototype.hasOwnProperty.call(serverFumeInfo, key)) {
      const element = serverFumeInfo[key];
      configParams = [
        ...configParams,
        {
          id: element.id,
          stationId: element.station_id,
          name: element.fume_name,
          code: element.fume_code,
          unit: element.fume_unit,
          status: mappingServerToClientStationConfigParamStatus(element.status),
          standardMin: element.standard_limit_min,
          standardMax: element.standard_limit_max,
          nationalMin: element.national_limit_min,
          nationalMax: element.national_limit_max,
          chartMin: element.limit_chart_min,
          chartMax: element.limit_chart_max,
          warningMin: element.warning_limit_min,
          warningMax: element.warning_limit_max,
          value: null,
          stationCode: element.station_code,
          trackings: [],
        },
      ];
    }
  }
  const station: IStation = {
    id: serverStation.id,
    path: serverStation.path,
    name: serverStation.name,
    link: `/stations/${serverStation.id}`,
    color: serverStation.color,
    structureType: mappingServerStationStructureType(
      serverStation.structure_type
    ),
    status: mappingServerStationStatus(serverStation.status),
    type: {
      id: serverStation.station_type_id,
      name: serverStation.station_type_name,
      key: getStationTypeKey(serverStation.station_type_id),
      color: null,
    },
    dataType: mappingServerStationDataType(serverStation.data_type),
    company: {
      id: serverStation.company_id,
    },
    configParams,
    fetchInterval: serverStation.time_sync, // unit second
    users: serverStation.users,
  };

  return station;
};

export const mappingServerStationStatus = (
  serverStationStatus: EStationStatusServerResponse
): EStationStatus => {
  switch (serverStationStatus) {
    case EStationStatusServerResponse.ERROR:
      return EStationStatus.ERROR_REPORT;
    case EStationStatusServerResponse.GOOD:
      return EStationStatus.OK;
    case EStationStatusServerResponse.LOST_CONNECTION:
      return EStationStatus.DISCONNECTED;
    case EStationStatusServerResponse.MAINTENANCE:
      return EStationStatus.MAINTENANCE;
  }
};

export const mappingClientStationStatus = (
  clientStationStatus?: EStationStatus
): EStationStatusServerResponse | undefined => {
  switch (clientStationStatus) {
    case EStationStatus.OK:
      return EStationStatusServerResponse.GOOD;
    case EStationStatus.DISCONNECTED:
      return EStationStatusServerResponse.LOST_CONNECTION;
    case EStationStatus.MAINTENANCE:
      return EStationStatusServerResponse.MAINTENANCE;
    case EStationStatus.ERROR_REPORT:
      return EStationStatusServerResponse.ERROR;
    default:
      return undefined;
  }
};

export const mappingServerToClientStationConfigParamStatus = (
  stt: EStationConfigParamStatusServerResponse
): EStationConfigParamsStatus => {
  switch (stt) {
    case EStationConfigParamStatusServerResponse.GOOD:
      return EStationConfigParamsStatus.OK;
    case EStationConfigParamStatusServerResponse.MAINTENANCE:
      return EStationConfigParamsStatus.MAINTENANCE;
    case EStationConfigParamStatusServerResponse.ERROR:
      return EStationConfigParamsStatus.ERROR;
  }
};

export const getStationStatusFromQuery = (
  statusQuery: string
): EStationStatus | undefined => {
  switch (statusQuery) {
    case 'ok':
      return EStationStatus.OK;
    case 'disconnected':
      return EStationStatus.DISCONNECTED;
    case 'maintenance':
      return EStationStatus.MAINTENANCE;
    case 'error-report':
      return EStationStatus.ERROR_REPORT;
    default:
      return undefined;
  }
};

export const extractStationStatusToQuery = (
  stationStatus: EStationStatus
): string => {
  switch (stationStatus) {
    case EStationStatus.OK:
      return 'ok';
    case EStationStatus.MAINTENANCE:
      return 'maintenance';
    case EStationStatus.DISCONNECTED:
      return 'disconnected';
    case EStationStatus.ERROR_REPORT:
      return 'error-report';
  }
};

export const mappingStationConfigParamsStatus = (
  status: EStationConfigParamsStatus
): string => {
  switch (status) {
    case EStationConfigParamsStatus.OK:
      return LanguageTranslateText.TEXT_TYPE_STATION_CONFIG_PARAMS_STATUS_OK;
    case EStationConfigParamsStatus.ERROR:
      return LanguageTranslateText.TEXT_TYPE_STATION_CONFIG_PARAMS_STATUS_ERROR;
    case EStationConfigParamsStatus.MAINTENANCE:
    default:
      return LanguageTranslateText.TEXT_TYPE_STATION_CONFIG_PARAMS_STATUS_MAINTENANCE;
  }
};

export const mappingStationStatus = (status: EStationStatus): string => {
  switch (status) {
    case EStationStatus.OK:
      return LanguageTranslateText.TEXT_TYPE_STATION_STATUS_OK;
    case EStationStatus.MAINTENANCE:
      return LanguageTranslateText.TEXT_TYPE_STATION_STATUS_MAINTENANCE;
    case EStationStatus.DISCONNECTED:
      return LanguageTranslateText.TEXT_TYPE_STATION_STATUS_DISCONNECTED;
    case EStationStatus.ERROR_REPORT:
    default:
      return LanguageTranslateText.TEXT_TYPE_STATION_STATUS_ERROR_REPORT;
  }
};

export const mappingServerStationDataType = (
  stationDataType: EStationDataTypeServerResponse
): EStationDataType => {
  switch (stationDataType) {
    case EStationDataTypeServerResponse.THONG_TU_24:
      return EStationDataType.THONG_TU_24;
    case EStationDataTypeServerResponse.INVENTIA_102:
      return EStationDataType.INVENTIA_102;
    case EStationDataTypeServerResponse.NGHI_DINH_47_1:
      return EStationDataType.NGHI_DINH_47_1;
    case EStationDataTypeServerResponse.NGHI_DINH_47_2:
      return EStationDataType.NGHI_DINH_47_2;
    case EStationDataTypeServerResponse.NGHI_DINH_47_3:
      return EStationDataType.NGHI_DINH_47_3;
  }
};

export const mappingClientStationDataType = (
  clientDataType: EStationDataType
): EStationDataTypeServerResponse => {
  switch (clientDataType) {
    case EStationDataType.THONG_TU_24:
      return EStationDataTypeServerResponse.THONG_TU_24;
    case EStationDataType.INVENTIA_102:
      return EStationDataTypeServerResponse.INVENTIA_102;
    case EStationDataType.NGHI_DINH_47_1:
      return EStationDataTypeServerResponse.NGHI_DINH_47_1;
    case EStationDataType.NGHI_DINH_47_2:
      return EStationDataTypeServerResponse.NGHI_DINH_47_2;
    case EStationDataType.NGHI_DINH_47_3:
      return EStationDataTypeServerResponse.NGHI_DINH_47_3;
  }
};

export const mappingServerStationStructureType = (
  stationStructureType: EStationStructureTypeServerResponse
): EStationStructureType => {
  switch (stationStructureType) {
    case EStationStructureTypeServerResponse.DATE:
      return EStationStructureType.DATE;
    case EStationStructureTypeServerResponse.ROOT:
      return EStationStructureType.ROOT;
    case EStationStructureTypeServerResponse.SUB_FOLDER:
      return EStationStructureType.SUB_FOLDER;
  }
};

export const mappingClientStationStructureType = (
  clientStructureType: EStationStructureType
): EStationStructureTypeServerResponse => {
  switch (clientStructureType) {
    case EStationStructureType.DATE:
      return EStationStructureTypeServerResponse.DATE;
    case EStationStructureType.ROOT:
      return EStationStructureTypeServerResponse.ROOT;
    case EStationStructureType.SUB_FOLDER:
      return EStationStructureTypeServerResponse.SUB_FOLDER;
  }
};

const stationAPI = {
  fetchStations,
  fetchStationsWithPagination,
  fetchStationsSummary,
  fetchStationRealtime,
  createStation,
  updateStation,
  deleteStation,
  canCreateNewStation,
  fetchStationsByStatus,
};

export default stationAPI;
