import { Box, useTheme } from '@mui/material';
import Spinner from '../async/Spinner';

interface Props {
  variant: 'modal' | 'drawer';
}

const LoadingForm: React.FC<Props> = ({ variant }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: theme => {
          if (variant === 'modal') {
            return theme.zIndex.modal + 10;
          }

          return theme.zIndex.drawer + 10;
        },
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spinner size={32} thickness={5} color={theme.palette.primary.dark} />
    </Box>
  );
};

export default LoadingForm;
