import { ELanguages, EModeThemes } from 'app/utils/enum';

export const globalConfig = {
  BASE_URL: process.env.REACT_APP_BASE_API_URL || 'http://localhost:3000',
  MODE_THEME: (process.env.REACT_APP_MODE_THEME ||
    EModeThemes.LIGHT_THEME) as EModeThemes,
  LANGUAGE: (process.env.REACT_APP_LANGUAGE || ELanguages.EN) as ELanguages,
  MIN_PASSWORD: process.env.REACT_APP_MIN_PASSWORD
    ? parseInt(process.env.REACT_APP_MIN_PASSWORD)
    : 6,
};
