import { Button } from '@mui/material';
import useUI from 'app/hooks/useUI';
import { LanguageTranslateText } from 'app/utils/enum';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EModalType } from '../enum';
import ModalWrapper from './ModalWrapper';

interface Props {
  title?: string;
  confirmationText?: string;
  cancellationText?: string;
  description?: string;
}

const ConfirmModal: React.FC<Props> = props => {
  const { t } = useTranslation();
  const { closeModal, modal } = useUI();
  const [loading, setLoading] = useState(false);

  const title: string =
    props.title || t(LanguageTranslateText.TEXT_CONFIRM_ALERT_TITLE);
  const confirmationText =
    props.confirmationText || t(LanguageTranslateText.TEXT_CONFIRM);
  const cancellationText =
    props.cancellationText || t(LanguageTranslateText.TEXT_CANCEL);
  const description =
    props.description ||
    t(LanguageTranslateText.TEXT_CONFIRM_ACTION_CANNOT_UNDONE);

  return (
    <ModalWrapper
      loading={loading}
      modalType={EModalType.CONFIRM_MODAL}
      title={title}
      closeable={true}
      actions={
        <>
          <Button autoFocus onClick={() => closeModal()}>
            {cancellationText}
          </Button>
          <Button
            onClick={() => {
              if (
                modal &&
                modal.open &&
                modal.props &&
                (modal.props as any).onConfirm
              ) {
                setLoading(true);
                (modal.props as any)
                  .onConfirm()
                  .then(() => {})
                  .finally(() => {
                    setLoading(false);
                    closeModal();
                  });
              }
            }}
          >
            {confirmationText}
          </Button>
        </>
      }
    >
      {description}
    </ModalWrapper>
  );
};

export default ConfirmModal;
