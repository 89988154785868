import { EUserRole } from 'app/types/user';
import React from 'react';
import { RouteProps } from 'react-router-dom';

const LoginPage = React.lazy(() => import('features/auth/login/LoginPage'));
const RegisterPage = React.lazy(
  () => import('features/auth/register/RegisterPage')
);
const RegisterSuccessPage = React.lazy(
  () => import('features/auth/register/RegisterSuccessPage')
);
const HomePage = React.lazy(() => import('features/home'));
const MonitorTable = React.lazy(() => import('features/monitors/table'));
const MonitorOnline = React.lazy(() => import('features/monitors/online'));
const Notifications = React.lazy(() => import('features/notifications'));
const NotFoundPage = React.lazy(() => import('features/not-found'));
const Reports = React.lazy(() => import('features/reports'));
const StationDetails = React.lazy(() => import('features/stations/details'));
const StationList = React.lazy(() => import('features/stations/list'));
const UserList = React.lazy(() => import('features/user/list'));
const Settings = React.lazy(() => import('features/settings'));
const ManageConfigurationStation = React.lazy(
  () => import('features/stations/manage')
);
const Companies = React.lazy(() => import('features/companies'));
const OwnerList = React.lazy(() => import('features/owner/list'));
const PlayGround = React.lazy(() => import('features/playground'));

export interface IRoute {
  exact: boolean;
  path: string;
  accessAbleRole: EUserRole[];
  component: RouteProps['component'];
}

export const routers: IRoute[] = [
  {
    exact: true,
    path: '/login',
    accessAbleRole: [EUserRole.ADMIN, EUserRole.USER, EUserRole.OWNER],
    component: LoginPage,
  },
  {
    exact: true,
    path: '/register',
    accessAbleRole: [EUserRole.ADMIN, EUserRole.USER, EUserRole.OWNER],
    component: RegisterPage,
  },
  {
    exact: true,
    path: '/register/success',
    accessAbleRole: [EUserRole.ADMIN, EUserRole.USER, EUserRole.OWNER],
    component: RegisterSuccessPage,
  },
  {
    exact: true,
    path: '/companies',
    accessAbleRole: [EUserRole.OWNER],
    component: Companies,
  },

  {
    exact: true,
    path: '/stations',
    accessAbleRole: [EUserRole.ADMIN, EUserRole.USER],
    component: StationList,
  },
  {
    exact: true,
    path: '/manage-stations',
    accessAbleRole: [EUserRole.ADMIN, EUserRole.USER],
    component: ManageConfigurationStation,
  },
  {
    exact: true,
    path: '/stations/:id',
    accessAbleRole: [EUserRole.ADMIN, EUserRole.USER],
    component: StationDetails,
  },
  {
    exact: true,
    path: '/monitors/table',
    accessAbleRole: [EUserRole.ADMIN, EUserRole.USER],
    component: MonitorTable,
  },
  {
    exact: true,
    path: '/monitors/online',
    accessAbleRole: [EUserRole.ADMIN, EUserRole.USER],
    component: MonitorOnline,
  },
  {
    exact: true,
    path: '/notifications',
    accessAbleRole: [EUserRole.ADMIN],
    component: Notifications,
  },
  {
    exact: true,
    path: '/users',
    accessAbleRole: [EUserRole.ADMIN, EUserRole.USER],
    component: UserList,
  },
  {
    exact: true,
    path: '/owners',
    accessAbleRole: [EUserRole.OWNER],
    component: OwnerList,
  },
  {
    exact: true,
    path: '/reports',
    accessAbleRole: [EUserRole.ADMIN, EUserRole.USER],
    component: Reports,
  },
  {
    exact: true,
    path: '/settings',
    accessAbleRole: [EUserRole.ADMIN, EUserRole.USER, EUserRole.OWNER],
    component: Settings,
  },
  {
    exact: true,
    path: '/playground',
    accessAbleRole: [EUserRole.ADMIN, EUserRole.USER, EUserRole.OWNER],
    component: PlayGround,
  },
  {
    exact: true,
    path: '/not-found',
    accessAbleRole: [EUserRole.ADMIN, EUserRole.USER, EUserRole.OWNER],
    component: NotFoundPage,
  },
  {
    exact: true,
    path: '/',
    accessAbleRole: [EUserRole.ADMIN, EUserRole.USER],
    component: HomePage,
  },
];
