import { EStationStatus, IStation, IStationType } from 'app/types/station';
import { createContext, Dispatch, SetStateAction, useState } from 'react';

interface StationState {
  stations: IStation[];
  stationTypes: IStationType[];
  stationStatuses: { status: EStationStatus; numberOfStation: number }[];
  selectedStations: (string | number)[];
  setStations: Dispatch<SetStateAction<IStation[]>>;
  setStationTypes: Dispatch<SetStateAction<IStationType[]>>;
  setStationStatuses: Dispatch<
    SetStateAction<{ status: EStationStatus; numberOfStation: number }[]>
  >;
  setSelectedStations: Dispatch<SetStateAction<(string | number)[]>>;
}

const StationContext = createContext<StationState>({
  stations: [],
  stationTypes: [],
  stationStatuses: [],
  selectedStations: [],
  setStations: () => {},
  setStationTypes: () => {},
  setSelectedStations: () => {},
  setStationStatuses: () => {},
});

export const StationProvider: React.FC = ({ children }) => {
  const [stations, setStations] = useState<IStation[]>([]);
  const [stationTypes, setStationTypes] = useState<IStationType[]>([]);
  const [selectedStations, setSelectedStations] = useState<(string | number)[]>(
    []
  );
  const [stationStatuses, setStationStatuses] = useState<
    { status: EStationStatus; numberOfStation: number }[]
  >([]);

  return (
    <StationContext.Provider
      value={{
        stations,
        stationTypes,
        selectedStations,
        stationStatuses,
        setStationStatuses,
        setStations,
        setStationTypes,
        setSelectedStations,
      }}
    >
      {children}
    </StationContext.Provider>
  );
};

export default StationContext;
