export interface IReportParam {
  time: Date;
  [key: string]: string | Date;
}

export interface IReport {
  data: IReportParam[];
}

export enum EReportType {
  ONE_MINUTE = 'one-minute',
  FIVE_MINUTE = 'five-minute',
  THIRTY_MINUTE = 'thirty-minute',
  HOUR = 'hour',
  DAY = 'day  ',
  MONTH = 'month',
}

export interface IReportParamDataServerResponse {
  time: string;
  value: string;
}

export enum EReportTypeServerResponse {
  ONE_MINUTE = 0,
  FIVE_MINUTE = 1,
  THIRTY_MINUTE = 2,
  HOUR = 3,
  DATE = 4,
  MONTH = 5,
}
