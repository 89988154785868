import { ICompany } from './company';

export interface IUser {
  id: number;
  name: string;
  email: string;
  role: EUserRole;
  phoneNumber: string;
  username: string;
  birthday?: string;
  avatar?: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  company: ICompany | null;
  stationIds: number[];
}

export enum EUserRole {
  ADMIN = 'admin',
  USER = 'user',
  OWNER = 'owner',
}

export interface IUserServerResponse {
  id: number;
  name: string;
  birthday: string;
  email: string;
  user_name: string;
  role: string;
  telephone: string;
  avatar?: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  station_ids?: number[];
  company_id?: number | null;
  company_logo?: string | null;
  company_name?: string | null;
  company_slogan?: string | null;
}

export enum EUserRoleServerResponse {
  ADMIN = 1,
  USER = 2,
}
