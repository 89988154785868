export enum ENotificationType {
  EMAIL = 'EMAIL',
  ZALO = 'ZALO',
  SMS = 'SMS',
  APP = 'SMS',
}

export enum ENotificationStatus {
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

export interface INotification {
  id: number;
  text: string;
  time: Date | string;
  station: {
    id: number;
    name: string;
  };
  types: ENotificationType[];
  status: ENotificationStatus;
}

export interface IAlertNotificationConfigurationsDetails {
  timeWarningDisconnect: number;
  numberNotifyNormalData: number;
  timeReAlert: number;
  senderName: string;
  senderMail: string;
  zaloReceivers: string[];
  mailReceivers: string[];
  configAlert: {
    type: EAlertNotificationType;
    sendZalo: boolean;
    sendApp: boolean;
    sendMail: boolean;
    enabled: boolean;
  }[];
}

export enum EAlertNotificationType {
  DISCONNECTED = 'DISCONNECTED',
  STATUS_ERROR = 'STATUS_ERROR',
  CROSS_NATIONAL_LINE_ERROR = 'CROSS_NATIONAL_LINE_ERROR',
  CROSS_WARNING_LINE_ERROR = 'CROSS_WARNING_LINE_ERROR',
  NORMAL = 'NORMAL',
}

export interface INotificationServerResponse {
  id: number;
  station_id: number;
  time: string;
  sent_mail: number;
  sent_sms: number;
  sent_zalo: number;
  status: number;
  content: string;
  created_at: string;
  updated_at: string;
  station_name: string;
}

export interface IAlertNotificationConfigurationsDetailsServerResponse {
  time_warning_disconnect: number;
  number_notify_normal_data: number;
  time_realert: number;
  sender_name: string;
  sender_mail: string;
  zalo_receivers: string;
  mail_receivers: string;
  config_alert: {
    [type: string]: {
      send_zalo: number;
      send_app: number;
      send_mail: number;
      enable: number;
    };
  };
}

export enum EAlertNotificationTypeServerResponse {
  DISCONNECTED = 5,
  STATUS_ERROR = 1,
  CROSS_NATIONAL_LINE_ERROR = 2,
  CROSS_WARNING_LINE_ERROR = 3,
  NORMAL = 4,
}
