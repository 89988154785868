import { ThemeProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { StyledEngineProvider } from '@mui/material';
import { ConfigSystemProvider } from 'app/context/config-system';
import { StationProvider } from 'app/context/station';
import { UIProvider } from 'app/context/ui';
import AlertManager from 'app/context/ui/alert/AlertManager';
import DrawerManager from 'app/context/ui/drawer/DrawerManager';
import ModalManager from 'app/context/ui/modal/ModalManager';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './app/context/auth';
import './app/languages';
import App from './app/layout/App';
import theme from './app/utils/theme';
import './index.css';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient();

const myErrorHandler = (error: Error, info: { componentStack: string }) => {
  console.log({ error });
  // Do something with the error
  // E.g. log to an error logging client here
};

const ErrorFallback: React.FC<any> = ({ error, resetErrorBoundary }) => {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

ReactDOM.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <UIProvider>
              <AuthProvider>
                <StationProvider>
                  <ConfigSystemProvider>
                    <Suspense fallback={<div>Loading...</div>}>
                      <ErrorBoundary
                        FallbackComponent={ErrorFallback}
                        onError={myErrorHandler}
                      >
                        <App />
                      </ErrorBoundary>
                    </Suspense>

                    <ModalManager />
                    <DrawerManager />
                    <AlertManager />
                  </ConfigSystemProvider>
                </StationProvider>
              </AuthProvider>
            </UIProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
