const DEFAULT_PAGE = process.env.REACT_APP_DEFAULT_PAGE
  ? parseInt(process.env.REACT_APP_DEFAULT_PAGE)
  : 1;

const DEFAULT_LIMIT = process.env.REACT_APP_DEFAULT_LIMIT
  ? parseInt(process.env.REACT_APP_DEFAULT_LIMIT)
  : 10;

const ROWS_PER_PAGE_OPTIONS = [1, 2, 3, 4, 5].map(it => it * DEFAULT_LIMIT);

const REPORT_LIMIT = process.env.REACT_APP_REPORT_LIMIT
  ? parseInt(process.env.REACT_APP_REPORT_LIMIT)
  : 30;

export const paginationConfig = {
  DEFAULT_PAGE,
  DEFAULT_LIMIT,
  ROWS_PER_PAGE_OPTIONS,
  REPORT_LIMIT,
};
