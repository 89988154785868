import { Box, Skeleton } from '@mui/material';
import useAuth from 'app/hooks/useAuth';
import useConfigSystem from 'app/hooks/useConfigSystem';
import useNotifications from 'app/hooks/useNotifications';
import useStation from 'app/hooks/useStation';
import { EUserRole } from 'app/types/user';
import { INFINITIES_NUMBER } from 'app/utils/constants';
import { routers } from 'app/utils/router';
import { envConfig } from 'configs/envConfig';
import { messaging } from 'configs/firebaseConfig';
import { getToken, onMessage } from 'firebase/messaging';
import { lazy, Suspense, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

const LayoutPage = lazy(() => import('./LayoutPage'));
const LoadingScreen = lazy(() => import('./LoadingScreen'));

function App() {
  const history = useHistory();
  const { pathname } = useLocation();
  const {
    refreshAuthUser,
    initialized,
    authenticated,
    user,
    setNotificationToken,
  } = useAuth();
  const [loading, setLoading] = useState(false);
  const {
    stationStatuses,
    fetchStations,
    setStations,
    setStationTypes,
    fetchStationsByStatus,
    setStationStatuses,
  } = useStation();
  const { handleNotificationAlert } = useNotifications();

  const { fetchStationTypes, getSystemCompany, setSystemCompany } =
    useConfigSystem();
  useEffect(() => {
    if (!pathname.includes('/register')) {
      initApp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  onMessage(messaging, payload => {
    handleNotificationAlert(payload.notification);
  });

  useEffect(() => {
    const initStations = () => {
      setLoading(true);
      fetchStations({})
        .then(data => {
          setStations(data);
          return fetchStationTypes({ page: 1, limit: INFINITIES_NUMBER });
        })
        .then(data => {
          setStationTypes(data.docs);
        })
        .catch(err => {
          setStations([]);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    if (authenticated && user && user.role !== EUserRole.OWNER) {
      initStations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, user]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        history.location.pathname !== '/login' &&
        !history.location.pathname.includes('/register') &&
        history.location.pathname !== '/playground'
      ) {
        fetchStationsByStatus({}).then(data => {
          setStationStatuses(data);
        });
      }
    }, 60 * 1000);

    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const initStationStatus = () => {
      if (stationStatuses.length === 0) {
        fetchStationsByStatus({}).then(data => {
          setStationStatuses(data);
        });
      }
    };

    if (pathname !== '/login' && !pathname.includes('/register')) {
      initStationStatus();
    }

    // eslint-disable-next-line
  }, []);

  const initApp = () => {
    getSystemCompany({})
      .then(data => {
        setSystemCompany(data);
        return refreshAuthUser();
      })
      .then(() => {
        return getToken(messaging, {
          vapidKey: envConfig.firebaseConfig.vapidKey,
        });
      })
      .then(token => {
        console.log({ token });
        if (token) {
          setNotificationToken(token);
        }
      });
  };

  return (
    <LayoutPage loading={loading}>
      <LoadingScreen loading={!initialized || loading} />
      <Suspense
        fallback={
          <Box sx={{ p: 2 }}>
            <Skeleton variant='rectangular' width='100%' height={300} />
          </Box>
        }
      >
        <Switch>
          {routers.map(route => {
            return (
              <Route
                key={route.path}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            );
          })}
        </Switch>
      </Suspense>
    </LayoutPage>
  );
}

export default App;
