import { paginationConfig } from 'app/configs';
import {
  EAlertNotificationType,
  EAlertNotificationTypeServerResponse,
  ENotificationStatus,
  ENotificationType,
  IAlertNotificationConfigurationsDetails,
  IAlertNotificationConfigurationsDetailsServerResponse,
  INotification,
  INotificationServerResponse,
} from 'app/types/notification';
import {
  IPaginationQuery,
  IPaginationQueryServer,
  IPaginationResponse,
  IPaginationServerResponse,
} from 'app/types/pagination';
import {
  EStationDataType,
  EStationDataTypeServerResponse,
  EStationStatus,
  EStationStatusServerResponse,
  EStationStructureType,
  EStationStructureTypeServerResponse,
  IStationConfigParam,
  IStationConfigParamServerResponse,
} from 'app/types/station';
import {
  isEmpty,
  mappingBoolean,
  mappingPaginationServerToClient,
} from 'app/utils/helper';
import queryString from 'query-string';
import { apiWrapper } from './axiosClient';
import {
  mappingServerStationDataType,
  mappingServerStationStatus,
  mappingServerStationStructureType,
  mappingServerToClientStationConfigParamStatus,
} from './stationAPI';

export type TConfigNotificationsArgs = {
  stationId: number;
  timeWarningDisconnect: number | string;
  numberNotifyNormalData: number | string;
  timeReAlert: number | string;
  senderName: string;
  senderMail: string;
  zaloReceivers: string[];
  mailReceivers: string[];
  configAlert: {
    type: EAlertNotificationType;
    sendZalo: boolean;
    sendApp: boolean;
    sendMail: boolean;
    enabled: boolean;
  }[];
};
export type TConfigNotificationsRes = {};
export type TFetchNotificationsArgs = IPaginationQuery;
export type TFetchNotificationsRes = IPaginationResponse<INotification>;
export type TFetchAlertNotificationConfigurationsArgs = {
  id: number;
};
export type TFetchAlertNotificationConfigurationsRes = {
  id: number;
  name: string;
  path: string;
  structureType: EStationStructureType;
  status: EStationStatus;
  type: number;
  dataType: EStationDataType;
  fetchInterval: number;
  configParams: IStationConfigParam[];
} & IAlertNotificationConfigurationsDetails;
export type TStoreNotificationTokenKeyArgs = {
  token: string;
};
export type TStoreNotificationTokenKeyRes =
  | {
      id: number;
      userId: number;
      type: string;
      key: string;
      confirmKey: string;
      updatedAt: string;
      createdAt: string;
    }
  | boolean;
export type TDeleteNotificationTokenKeyArgs = {
  confirmKey: string;
};
export type TDeleteNotificationTokenKeyRes = boolean;

type ApiConfigNotificationsArgs = {
  station_id: number;
  time_warning_disconnect: number | string;
  number_notify_normal_data: number | string;
  time_realert: number | string;
  sender_name: string;
  sender_mail: string;
  zalo_receivers: string;
  mail_receivers: string;
  config_alert: {
    [type: string]: {
      send_zalo: number;
      send_app: number;
      send_mail: number;
      enable: number;
    };
  };
};
type ApiConfigNotificationsRes = string;
type ApiFetchNotificationsArgs = IPaginationQueryServer;
type ApiFetchNotificationsRes =
  IPaginationServerResponse<INotificationServerResponse>;
type ApiFetchAlertNotificationConfigurationsRes = {
  id: number;
  name: string;
  company_id: number;
  station_type_id: number;
  status: EStationStatusServerResponse;
  path: string;
  server_path: string;
  structure_type: EStationStructureTypeServerResponse;
  data_type: EStationDataTypeServerResponse;
  time_sync: number;
  station_code: string | null;
  last_time_sync_data: string;
  last_time_have_data: string;
  start_sync_data_time: string;
  sync_old_data_status: 1;
  current_time_sync_old_data: string;
  fume_info: IStationConfigParamServerResponse[];
} & IAlertNotificationConfigurationsDetailsServerResponse;
type ApiStoreNotificationTokenKeyArgs = {
  key: string;
};
type ApiStoreNotificationTokenKeyRes = {
  id: number;
  user_id: number;
  type: string;
  key: string;
  confirm_key: string;
  updated_at: string;
  created_at: string;
};
type ApiDeleteNotificationTokenKeyArgs = {
  confirm_key: string;
};
type ApiDeleteNotificationTokenKeyRes = string;

const { DEFAULT_PAGE, DEFAULT_LIMIT } = paginationConfig;

const fetchNotifications = async (
  query: TFetchNotificationsArgs
): Promise<TFetchNotificationsRes> => {
  const queryObject: ApiFetchNotificationsArgs = {
    page: query.page || DEFAULT_PAGE,
    per_page: query.limit || DEFAULT_LIMIT,
  };
  const url = queryString.stringifyUrl(
    {
      url: '/api/notifications', // TODO: should update later when API is ready
      query: queryObject as any,
    },
    {
      arrayFormat: 'comma',
    }
  );
  const serverData = await apiWrapper.get<ApiFetchNotificationsRes>(url);
  const result = mappingPaginationServerToClient<
    INotificationServerResponse,
    INotification
  >({
    paginationData: serverData,
    mappingFunc: mappingServerNotification,
  });

  return result;
};

const fetchAlertNotificationConfigurations = async (
  params: TFetchAlertNotificationConfigurationsArgs
): Promise<TFetchAlertNotificationConfigurationsRes> => {
  const id = params.id;
  const url = `/api/stations/${id}`;

  const serverResponse =
    await apiWrapper.get<ApiFetchAlertNotificationConfigurationsRes>(url);

  let configAlert: IAlertNotificationConfigurationsDetails['configAlert'] = [];
  for (const key in serverResponse.config_alert) {
    if (
      Object.prototype.hasOwnProperty.call(serverResponse.config_alert, key)
    ) {
      const element = serverResponse.config_alert[key];

      configAlert = [
        ...configAlert,
        {
          type: mappingAlertNotificationTypeServerToClient(key),
          sendZalo: mappingBoolean(
            parseInt(element.send_zalo.toString()),
            'number-to-boolean'
          ) as boolean,
          sendApp: mappingBoolean(
            parseInt(element.send_app.toString()),
            'number-to-boolean'
          ) as boolean,
          sendMail: mappingBoolean(
            parseInt(element.send_mail.toString()),
            'number-to-boolean'
          ) as boolean,
          enabled: mappingBoolean(
            parseInt(element.enable.toString()),
            'number-to-boolean'
          ) as boolean,
        },
      ];
    }
  }

  const result: TFetchAlertNotificationConfigurationsRes = {
    id: serverResponse.id,
    name: serverResponse.name,
    path: serverResponse.path,
    structureType: mappingServerStationStructureType(
      serverResponse.structure_type
    ),
    status: mappingServerStationStatus(serverResponse.status),
    type: serverResponse.station_type_id,
    dataType: mappingServerStationDataType(serverResponse.data_type),
    fetchInterval: serverResponse.time_sync,
    configParams: serverResponse.fume_info.map(info => {
      const param: IStationConfigParam = {
        id: info.id,
        stationId: info.station_id,
        name: info.fume_name,
        code: info.fume_code,
        unit: info.fume_unit,
        status: mappingServerToClientStationConfigParamStatus(info.status),
        standardMin: info.standard_limit_min,
        standardMax: info.standard_limit_max,
        nationalMin: info.national_limit_min,
        nationalMax: info.national_limit_max,
        chartMin: info.limit_chart_min,
        chartMax: info.limit_chart_max,
        warningMin: info.warning_limit_min,
        warningMax: info.warning_limit_max,
        value: null,
        stationCode: info.station_code,
        trackings: [],
      };
      return param;
    }),
    timeWarningDisconnect: serverResponse.time_warning_disconnect,
    numberNotifyNormalData: serverResponse.number_notify_normal_data,
    timeReAlert: serverResponse.time_realert,
    senderName: serverResponse.sender_name,
    senderMail: serverResponse.sender_mail,
    zaloReceivers: isEmpty(serverResponse.zalo_receivers)
      ? []
      : serverResponse.zalo_receivers.split(';'),
    mailReceivers: isEmpty(serverResponse.mail_receivers)
      ? []
      : serverResponse.mail_receivers.split(';'),
    configAlert,
  };

  return result;
};

const configNotifications = async (
  params: TConfigNotificationsArgs
): Promise<TConfigNotificationsRes> => {
  let body: ApiConfigNotificationsArgs = {
    station_id: params.stationId,
    time_warning_disconnect: isEmpty(params.timeWarningDisconnect.toString())
      ? ''
      : parseInt(params.timeWarningDisconnect.toString()),
    number_notify_normal_data: isEmpty(params.numberNotifyNormalData.toString())
      ? ''
      : parseInt(params.numberNotifyNormalData.toString()),
    time_realert: isEmpty(params.timeReAlert.toString())
      ? ''
      : parseInt(params.timeReAlert.toString()),
    sender_name: params.senderName,
    sender_mail: params.senderMail,
    zalo_receivers: params.zaloReceivers.join(';'),
    mail_receivers: params.mailReceivers.join(';'),
    config_alert: {},
  };

  params.configAlert.forEach(element => {
    const type = mappingAlertNotificationTypeClientToServer(element.type);
    body = {
      ...body,
      config_alert: {
        ...body.config_alert,
        [type]: {
          send_zalo: mappingBoolean(
            element.sendZalo,
            'boolean-to-number'
          ) as number,
          send_app: mappingBoolean(
            element.sendApp,
            'boolean-to-number'
          ) as number,
          send_mail: mappingBoolean(
            element.sendMail,
            'boolean-to-number'
          ) as number,
          enable: mappingBoolean(
            element.enabled,
            'boolean-to-number'
          ) as number,
        },
      },
    };
  });

  const result = await apiWrapper.put<
    ApiConfigNotificationsArgs,
    ApiConfigNotificationsRes
  >('/api/station/config_alert', body, {
    contentType: 'application/json',
  });

  return result;
};

const storeNotificationTokenKey = async (
  params: TStoreNotificationTokenKeyArgs
): Promise<TStoreNotificationTokenKeyRes> => {
  const body: ApiStoreNotificationTokenKeyArgs = {
    key: params.token,
  };

  const res = await apiWrapper.post<
    ApiStoreNotificationTokenKeyArgs,
    ApiStoreNotificationTokenKeyRes
  >('/api/firebase/web', body, {
    contentType: 'application/json',
  });
  if (res.id) {
    return {
      id: res.id,
      userId: res.user_id,
      type: res.type,
      key: res.key,
      confirmKey: res.confirm_key,
      updatedAt: res.updated_at,
      createdAt: res.created_at,
    };
  }

  return false;
};

const deleteNotificationTokenKey = async (
  params: TDeleteNotificationTokenKeyArgs
): Promise<TDeleteNotificationTokenKeyRes> => {
  const body: ApiDeleteNotificationTokenKeyArgs = {
    confirm_key: params.confirmKey,
  };

  const res = await apiWrapper._delete<
    ApiDeleteNotificationTokenKeyArgs,
    ApiDeleteNotificationTokenKeyRes
  >('/api/firebase/key', body, {
    contentType: 'application/json',
  });
  if (typeof res === 'string') {
    return true;
  }

  return false;
};

const mappingServerNotification = (
  serverNotification: INotificationServerResponse
): INotification => {
  let types: ENotificationType[] = [];
  if (mappingBoolean(serverNotification.sent_mail)) {
    types = [...types, ENotificationType.EMAIL];
  }
  if (mappingBoolean(serverNotification.sent_sms)) {
    types = [...types, ENotificationType.SMS];
  }
  if (mappingBoolean(serverNotification.sent_zalo)) {
    types = [...types, ENotificationType.ZALO];
  }

  return {
    id: serverNotification.id,
    text: serverNotification.content,
    time: new Date(serverNotification.time),
    station: {
      id: serverNotification.station_id,
      name: serverNotification.station_name,
    },
    types,
    status: mappingNotificationStatus(serverNotification.status),
  };
};

const mappingNotificationStatus = (
  serverStatus: number
): ENotificationStatus => {
  switch (serverStatus) {
    case 0:
      return ENotificationStatus.PENDING;
    case 1:
      return ENotificationStatus.SUCCEEDED;
    default:
      return ENotificationStatus.FAILED;
  }
};

const mappingAlertNotificationTypeClientToServer = (
  type: EAlertNotificationType
): EAlertNotificationTypeServerResponse => {
  switch (type) {
    case EAlertNotificationType.DISCONNECTED:
      return EAlertNotificationTypeServerResponse.DISCONNECTED;
    case EAlertNotificationType.STATUS_ERROR:
      return EAlertNotificationTypeServerResponse.STATUS_ERROR;
    case EAlertNotificationType.CROSS_NATIONAL_LINE_ERROR:
      return EAlertNotificationTypeServerResponse.CROSS_NATIONAL_LINE_ERROR;
    case EAlertNotificationType.CROSS_WARNING_LINE_ERROR:
      return EAlertNotificationTypeServerResponse.CROSS_WARNING_LINE_ERROR;
    case EAlertNotificationType.NORMAL:
      return EAlertNotificationTypeServerResponse.NORMAL;
  }
};

const mappingAlertNotificationTypeServerToClient = (
  type: number | string
): EAlertNotificationType => {
  const typeParsed = parseInt(type.toString());
  switch (typeParsed) {
    case EAlertNotificationTypeServerResponse.DISCONNECTED:
      return EAlertNotificationType.DISCONNECTED;
    case EAlertNotificationTypeServerResponse.STATUS_ERROR:
      return EAlertNotificationType.STATUS_ERROR;
    case EAlertNotificationTypeServerResponse.CROSS_NATIONAL_LINE_ERROR:
      return EAlertNotificationType.CROSS_NATIONAL_LINE_ERROR;
    case EAlertNotificationTypeServerResponse.CROSS_WARNING_LINE_ERROR:
      return EAlertNotificationType.CROSS_WARNING_LINE_ERROR;
    case EAlertNotificationTypeServerResponse.NORMAL:
    default:
      return EAlertNotificationType.NORMAL;
  }
};

const notificationsAPI = {
  fetchNotifications,
  configNotifications,
  fetchAlertNotificationConfigurations,
  storeNotificationTokenKey,
  deleteNotificationTokenKey,
};

export default notificationsAPI;
