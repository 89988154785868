import {
  BuildOutlined,
  CheckCircleOutline,
  ReportOutlined,
  SyncDisabled,
} from '@mui/icons-material';
import { ChipProps, useTheme } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import reportAPI from 'app/api/reportAPI';
import stationAPI, {
  TCreateStationArgs,
  TCreateStationRes,
  TDeleteStationArgs,
  TDeleteStationRes,
  TFetchStationRealtimeArgs,
  TFetchStationRealtimeRes,
  TFetchStationsArgs,
  TFetchStationsByStatusArgs,
  TFetchStationsByStatusRes,
  TFetchStationsSummaryArgs,
  TFetchStationsSummaryRes,
  TUpdateStationArgs,
  TUpdateStationRes,
} from 'app/api/stationAPI';
import StationContext from 'app/context/station';
import { IPaginationResponse } from 'app/types/pagination';
import { EReportType } from 'app/types/report';
import {
  EStationConfigParamsStatus,
  EStationStatus,
  IStation,
  IStationType,
} from 'app/types/station';
import { LanguageTranslateText } from 'app/utils/enum';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import useAuth from './useAuth';

type ExportReportArgs = {
  id: number;
  startTime: string;
  endTime: string;
  reportType: EReportType;
  paramKey: string[];
  inValidData: boolean;
};

function useStation() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { handleError } = useAuth();
  const {
    stations,
    stationTypes,
    selectedStations,
    stationStatuses,
    setStationStatuses,
    setStations,
    setStationTypes,
    setSelectedStations,
  } = useContext(StationContext);
  const { mutateAsync: fetchStations } = useMutation<
    IStation[],
    any,
    TFetchStationsArgs
  >(params => {
    const { search, status, type } = params;
    return stationAPI.fetchStations({
      search,
      status,
      type,
    });
  });
  const { mutateAsync: fetchStationsWithPagination } = useMutation<
    IPaginationResponse<IStation>,
    any,
    TFetchStationsArgs
  >(params => {
    const { search, status, type, page, limit, ids } = params;
    return stationAPI.fetchStationsWithPagination({
      search,
      status,
      type,
      page,
      limit,
      ids,
    });
  });
  const {
    isLoading: fetchStationsSummaryLoading,
    data: fetchStationsSummaryResult,
    mutateAsync: fetchStationsSummary,
  } = useMutation<TFetchStationsSummaryRes, any, TFetchStationsSummaryArgs>(
    params => {
      const { search, ids } = params;
      return stationAPI.fetchStationsSummary({
        ids,
        search,
      });
    },
    {
      onError: err => {
        handleError(err);
      },
    }
  );
  const {
    isLoading: fetchStationRealtimeLoading,
    data: fetchStationRealtimeResult,
    mutateAsync: fetchStationRealtime,
  } = useMutation<TFetchStationRealtimeRes, any, TFetchStationRealtimeArgs>(
    params => {
      const { id, latestTime } = params;
      return stationAPI.fetchStationRealtime({
        id,
        latestTime,
      });
    },
    {
      onError: err => {
        handleError(err);
      },
    }
  );
  const { mutateAsync: createStation } = useMutation<
    TCreateStationRes,
    any,
    TCreateStationArgs
  >(args => {
    return stationAPI.createStation(args);
  });
  const { mutateAsync: updateStation } = useMutation<
    TUpdateStationRes,
    any,
    TUpdateStationArgs
  >(args => {
    return stationAPI.updateStation(args);
  });
  const { mutateAsync: deleteStation } = useMutation<
    TDeleteStationRes,
    any,
    TDeleteStationArgs
  >(args => {
    return stationAPI.deleteStation(args);
  });
  const { mutateAsync: canCreateNewStation } = useMutation<boolean, any, any>(
    () => {
      return stationAPI.canCreateNewStation();
    }
  );
  const { mutateAsync: fetchStationsByStatus } = useMutation<
    TFetchStationsByStatusRes,
    any,
    TFetchStationsByStatusArgs
  >(params => {
    return stationAPI.fetchStationsByStatus(params);
  });

  const { mutateAsync: exportReportData } = useMutation<
    string,
    any,
    ExportReportArgs
  >(({ startTime, endTime, reportType, paramKey, inValidData, id }) => {
    return reportAPI.exportReportData(id, {
      startTime,
      endTime,
      reportType,
      inValidData,
      paramKey,
    });
  });

  const getStationStatusConfigParamsColor = (
    status: EStationConfigParamsStatus
  ): ChipProps['color'] => {
    switch (status) {
      case EStationConfigParamsStatus.OK:
        return 'success';
      case EStationConfigParamsStatus.MAINTENANCE:
        return 'warning';
      case EStationConfigParamsStatus.ERROR:
      default:
        return 'error';
    }
  };

  const getStationStatusColor = (status: EStationStatus): string => {
    switch (status) {
      case EStationStatus.OK:
        return theme.palette.success.main;
      case EStationStatus.DISCONNECTED:
        return blueGrey['500'];
      case EStationStatus.MAINTENANCE:
        return theme.palette.warning.main;
      case EStationStatus.ERROR_REPORT:
      default:
        return theme.palette.error.main;
    }
  };

  const getStationStatusLabel = (status: EStationStatus): string => {
    switch (status) {
      case EStationStatus.OK:
        return t(LanguageTranslateText.TEXT_TYPE_STATION_STATUS_OK);
      case EStationStatus.DISCONNECTED:
        return t(LanguageTranslateText.TEXT_TYPE_STATION_STATUS_DISCONNECTED);
      case EStationStatus.MAINTENANCE:
        return t(LanguageTranslateText.TEXT_TYPE_STATION_STATUS_MAINTENANCE);
      case EStationStatus.ERROR_REPORT:
      default:
        return t(LanguageTranslateText.TEXT_TYPE_STATION_STATUS_ERROR_REPORT);
    }
  };

  const getStationStatusIcon = (status: EStationStatus): any => {
    switch (status) {
      case EStationStatus.OK:
        return (
          <CheckCircleOutline style={{ color: theme.palette.success.main }} />
        );
      case EStationStatus.MAINTENANCE:
        return <BuildOutlined style={{ color: theme.palette.warning.main }} />;
      case EStationStatus.DISCONNECTED:
        return <SyncDisabled />;
      case EStationStatus.ERROR_REPORT:
        return <ReportOutlined color='error' />;
    }
  };

  const types = useMemo((): {
    type: IStationType;
    stations: IStation[];
  }[] => {
    let types: {
      type: IStationType;
      stations: IStation[];
    }[] = [];

    types = stationTypes.map(t => ({
      type: t,
      stations: stations.filter(
        station => station.type.id.toString() === t.id.toString()
      ),
    }));

    return types;
  }, [stations, stationTypes]);

  return {
    stations,
    types,
    stationStatuses,
    stationTypes,
    selectedStations,
    fetchStationsSummaryLoading,
    fetchStationsSummaryResult,
    fetchStationRealtimeLoading,
    fetchStationRealtimeResult,
    fetchStationRealtime,
    fetchStationsSummary,
    getStationStatusConfigParamsColor,
    getStationStatusColor,
    setStations,
    fetchStations,
    fetchStationsWithPagination,
    createStation,
    updateStation,
    deleteStation,
    getStationStatusIcon,
    getStationStatusLabel,
    exportReportData,
    setStationTypes,
    setSelectedStations,
    canCreateNewStation,
    fetchStationsByStatus,
    setStationStatuses,
  };
}

export default useStation;
