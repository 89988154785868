export enum EModalType {
  SAMPLE_MODAL = 'sample-modal',
  CONFIRM_MODAL = 'confirm-modal',
}

export enum EDrawerType {
  SAMPLE_DRAWER = 'sample-drawer',
  SIDEBAR_DRAWER = 'sidebar-drawer',
  FILTER_STATION_DRAWER = 'filter-station-drawer',
  EDIT_USER_DRAWER = 'edit-user-drawer',
  CREATE_UPDATE_STATION_TYPE_DRAWER = 'create-update-station-type-drawer',
  CREATE_STATION_DRAWER = 'create-station-drawer',
  UPDATE_STATION_DRAWER = 'update-station-drawer',
  CREATE_COMPANY_DRAWER = 'create-company-drawer',
  UPDATE_COMPANY_DRAWER = 'update-company-drawer',
  CREATE_CUSTOMER_COMPANY_DRAWER = 'create-customer-company-drawer',
  UPDATE_CUSTOMER_COMPANY_DRAWER = 'update-customer-company-drawer',
  CREATE_USER_DRAWER = 'create-user-drawer',
  UPDATE_USER_DRAWER = 'update-user-drawer',
  CREATE_OWNER_DRAWER = 'create-owner-drawer',
  UPDATE_OWNER_DRAWER = 'update-owner-drawer',
  UPDATE_STATION_NOTIFICATION_CONFIGURATIONS_DRAWER = 'update-station-notification-configurations-drawer',
}
