import { ISelectOption } from 'app/types/shared';
import { EStationDataType, EStationStructureType } from 'app/types/station';

export const FETCH_INTERVAL = 5 * 1000;
export const DEBOUNCE_DELAY = 700;

export const stationStructureTypes: ISelectOption<EStationStructureType>[] = [
  {
    key: EStationStructureType.DATE,
    value: EStationStructureType.DATE,
    label: 'Date',
  },
  {
    key: EStationStructureType.SUB_FOLDER,
    value: EStationStructureType.SUB_FOLDER,
    label: 'Sub folder',
  },
  {
    key: EStationStructureType.ROOT,
    value: EStationStructureType.ROOT,
    label: 'Root',
  },
];

export const stationDataTypes: ISelectOption<EStationDataType>[] = [
  {
    key: EStationDataType.THONG_TU_24,
    value: EStationDataType.THONG_TU_24,
    label: 'Thông tư 24',
  },
  {
    key: EStationDataType.INVENTIA_102,
    value: EStationDataType.INVENTIA_102,
    label: 'Inventia 102',
  },
  {
    key: EStationDataType.NGHI_DINH_47_1,
    value: EStationDataType.NGHI_DINH_47_1,
    label: 'Nghị định 47 (1)',
  },
  {
    key: EStationDataType.NGHI_DINH_47_2,
    value: EStationDataType.NGHI_DINH_47_2,
    label: 'Nghị định 47 (2)',
  },
  {
    key: EStationDataType.NGHI_DINH_47_3,
    value: EStationDataType.NGHI_DINH_47_3,
    label: 'Nghị định 47 (3)',
  },
];

export const SAMPLE_IMAGE = '/sample-image.jpeg';

export const INFINITIES_NUMBER = 1000000000000;

export const ALERT_CLOSE_TIMEOUT = 10 * 1000;
