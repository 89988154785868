import { paginationConfig } from 'app/configs';
import { ICompany, ICompanyServerResponse } from 'app/types/company';
import {
  IPaginationQuery,
  IPaginationResponse,
  IPaginationServerResponse,
} from 'app/types/pagination';
import { IStationType, IStationTypeServerResponse } from 'app/types/station';
import queryString from 'query-string';
import { apiWrapper, axiosClientOwner } from './axiosClient';
import { mappingCompanyServer } from './companyAPI';

export type TCreateStationTypeArgs = {
  name: string;
  color: string;
};
export type TCreateStationTypeRes = string;
export type TUpdateStationTypeArgs = {
  id: number;
  name: string;
  color: string;
};
export type TUpdateStationTypeRes = string;
export type TDeleteStationTypeArgs = {
  id: number;
};
export type TDeleteStationTypeRes = string;
export type TGetOwnerCompanyArgs = {};
export type TGetOwnerCompanyRes = ICompany;

type ApiCreateStationTypeArgs = {
  name: string;
  color: string | null;
};
type ApiCreateStationTypeRes = string;
type ApiUpdateStationTypeArgs = {
  id: number;
  name: string;
  color: string | null;
};
type ApiUpdateStationTypeRes = string;
type ApiDeleteStationTypeArgs = {
  id: number;
};
type ApiDeleteStationTypeRes = string;
type ApiGetOwnerCompanyArgs = {};
type ApiGetOwnerCompanyRes = ICompanyServerResponse;

const { DEFAULT_PAGE, DEFAULT_LIMIT } = paginationConfig;
const STATION_TYPE_API_BASE_URL = '/api/station_types';

const DEFAULT_SYS_IMAGE_URL =
  'https://t4.ftcdn.net/jpg/01/00/76/57/360_F_100765796_hVO2AGkofuyqsiiPGd4rdEkjq1FaC11k.jpg';

const getSystemImageUrl = (): Promise<string> => {
  return Promise.resolve(DEFAULT_SYS_IMAGE_URL);
};

const getSystemCompany = async (
  param: TGetOwnerCompanyArgs
): Promise<TGetOwnerCompanyRes> => {
  const queryObject: ApiGetOwnerCompanyArgs = {};
  const url = queryString.stringifyUrl({
    url: '/api/owner_company',
    query: queryObject,
  });

  const result = await axiosClientOwner.get<any, ApiGetOwnerCompanyRes>(url);

  return mappingCompanyServer(result);
};

const fetchStationTypes = async (
  query: IPaginationQuery = {}
): Promise<IPaginationResponse<IStationType>> => {
  const queryObject = {
    page: query.page || DEFAULT_PAGE,
    per_page: query.limit || DEFAULT_LIMIT,
  };
  const url = queryString.stringifyUrl({
    url: '/api/station_types',
    query: queryObject,
  });
  const serverData = await apiWrapper.get<
    IPaginationServerResponse<IStationTypeServerResponse>
  >(url);
  const result: IPaginationResponse<IStationType> = {
    totalDocs: parseInt(serverData.total.toString()),
    totalPages: parseInt(serverData.last_page.toString()),
    page: parseInt(serverData.current_page.toString()),
    limit: parseInt(serverData.per_page.toString()),
    docs: serverData.data.map(doc => mappingServerStationType(doc)),
  };

  return result;
};

const createStationType = async (params: {
  name: string;
  color: string | null;
}): Promise<string> => {
  const data = await apiWrapper.post<
    ApiCreateStationTypeArgs,
    ApiCreateStationTypeRes
  >(
    `${STATION_TYPE_API_BASE_URL}/create`,
    {
      name: params.name,
      color: params.color,
    },
    {
      contentType: 'multipart/form-data',
    }
  );

  return data;
};

const updateStationType = async (
  id: number,
  params: { name: string; color: string | null }
): Promise<string> => {
  const data = await apiWrapper.post<
    ApiUpdateStationTypeArgs,
    ApiUpdateStationTypeRes
  >(
    `${STATION_TYPE_API_BASE_URL}/update`,
    {
      id,
      name: params.name,
      color: params.color,
    },
    {
      contentType: 'multipart/form-data',
    }
  );

  return data;
};

const deleteStationType = async (id: number): Promise<string> => {
  const data = await apiWrapper.post<
    ApiDeleteStationTypeArgs,
    ApiDeleteStationTypeRes
  >(
    `${STATION_TYPE_API_BASE_URL}/delete`,
    {
      id,
    },
    {
      contentType: 'multipart/form-data',
    }
  );

  return data;
};

const mappingServerStationType = (
  serverStationType: IStationTypeServerResponse
): IStationType => {
  const stationType: IStationType = {
    id: serverStationType.id,
    key: getStationTypeKey(serverStationType.id),
    name: serverStationType.name,
    color: serverStationType.color,
  };

  return stationType;
};

export const getStationTypeKey = (id: number): string => {
  return `station-type-${id}`;
};

const configSystemAPI = {
  getSystemImageUrl,
  fetchStationTypes,
  createStationType,
  updateStationType,
  deleteStationType,
  getSystemCompany,
};

export default configSystemAPI;
