import authAPI, {
  TRegisterArgs,
  TRegisterRes,
  TUpdateMeArgs,
  TUpdateMeRes,
} from 'app/api/authAPI';
import AuthContext from 'app/context/auth';
import { EUserRole, IUser } from 'app/types/user';
import { routers } from 'app/utils/router';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import useAlert from './useAlert';

type LoginArgs = {
  username: string;
  password: string;
};
type LoginResponse = {
  user: IUser | null;
  token: string;
  isOwnerCompany: boolean;
};
type LoginError = { message: string };

type GetMeArgs = {};
type GetMeResponse = { user: IUser | null; isOwnerCompany: boolean };
type GetMeError = { message: string };

function useAuth() {
  const context = useContext(AuthContext);
  const { alertError } = useAlert();
  const {
    setAuthenticated,
    setInitialized,
    setErrorMessage,
    setUser,
    setIsOwnerCompany,
  } = context;
  const history = useHistory();
  const { mutateAsync: login } = useMutation<
    LoginResponse,
    LoginError,
    LoginArgs
  >(({ username, password }) => {
    return authAPI.login(username, password);
  });
  const { mutateAsync: register } = useMutation<
    TRegisterRes,
    any,
    TRegisterArgs
  >(params => {
    return authAPI.registerTempUser(params);
  });

  const { mutateAsync: getMe } = useMutation<
    GetMeResponse,
    GetMeError,
    GetMeArgs
  >(() => {
    return authAPI.getMe();
  });
  const { mutateAsync: logout } = useMutation<any, any>(() => {
    return authAPI.logout();
  });
  const { mutateAsync: updateMe } = useMutation<
    TUpdateMeRes,
    string,
    TUpdateMeArgs
  >(params => {
    return authAPI.updateMe(params);
  });

  const refreshAuthUser = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      return getMe({})
        .then(data => {
          setInitialized(true);
          setAuthenticated(true);
          setErrorMessage(null);
          setUser(data.user);
          setIsOwnerCompany(data.isOwnerCompany);

          if (!data.user) {
            history.push('/login');
            return reject({});
          } else {
            if (history.location.pathname === '/login') {
              if (data.user.role === EUserRole.OWNER) {
                history.push('/owners');
              } else {
                history.push('/');
              }
            } else {
              const currentRoutePath =
                history.location.pathname + history.location.search;
              const isUserAccessAble = routers.some(route => {
                return (
                  currentRoutePath.includes(route.path) &&
                  route.accessAbleRole.includes(data.user!.role)
                );
              });
              if (!isUserAccessAble) {
                setUser(null);
                setAuthenticated(false);
                history.push('/login');
                return reject();
              }
            }

            return resolve();
          }
        })
        .catch(err => {
          setInitialized(true);
          setAuthenticated(false);
          setErrorMessage(err.message);
          setUser(null);
          setIsOwnerCompany(false);
          history.push('/login');
          return reject({});
        })
        .finally(() => {});
    });
  };

  const handleLogout = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          localStorage.removeItem('token');
          setInitialized(true);
          setAuthenticated(false);
          setErrorMessage(null);
          setUser(null);
          setIsOwnerCompany(false);
          history.push('/login');
          resolve();
        })
        .catch(err => {
          setInitialized(true);
          setAuthenticated(false);
          setErrorMessage(err.message);
          setUser(null);
          setIsOwnerCompany(false);
          alertError(err.message);
          reject();
        })
        .finally(() => {});
    });
  };

  const handleError = (err: any) => {
    if (err && typeof err === 'string') {
      if (err === 'Unauthenticated.') {
        logout().then(() => {
          alertError(err);
        });
      } else {
        alertError(err);
      }
    } else if (err && err.message && typeof err.message === 'string') {
      if (err.message === 'Unauthenticated.') {
        logout().then(() => {
          alertError(err.message);
        });
      } else {
        alertError(err.message);
      }
    } else {
      alertError('Something wrong');
    }
  };

  return {
    ...context,
    login,
    register,
    logout: handleLogout,
    getMe,
    updateMe,
    refreshAuthUser,
    handleError,
  };
}

export default useAuth;
