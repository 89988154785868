import { envConfig } from 'configs/envConfig';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging } from 'firebase/messaging';

// Initialize Firebase
const firebaseApp = initializeApp({
  apiKey: envConfig.firebaseConfig.apiKey,
  authDomain: envConfig.firebaseConfig.authDomain,
  projectId: envConfig.firebaseConfig.projectId,
  storageBucket: envConfig.firebaseConfig.storageBucket,
  messagingSenderId: envConfig.firebaseConfig.messagingSenderId,
  appId: envConfig.firebaseConfig.appId,
  measurementId: envConfig.firebaseConfig.messagingSenderId,
});
export const analytics = getAnalytics(firebaseApp);
export const messaging = getMessaging(firebaseApp);
