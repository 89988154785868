const nodeEnv = process.env.REACT_APP_NODE_ENV || 'development';
const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || '#0062A9';
const secondaryColor = process.env.REACT_APP_SECONDARY_COLOR || '#4a148c';
const baseURL = process.env.REACT_APP_BASE_API_URL || 'http://localhost:84';
const baseURLOwner =
  process.env.REACT_APP_BASE_API_URL_OWNER || 'http://localhost:84';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
};

const envConfig = {
  nodeEnv,
  baseURL,
  baseURLOwner,
  primaryColor,
  secondaryColor,
  firebaseConfig,
};

export { envConfig };
