import { IUser } from 'app/types/user';
import { createContext, Dispatch, SetStateAction, useState } from 'react';

interface AuthState {
  authenticated: boolean;
  errorMessage: string | null;
  user?: IUser | null;
  initialized: boolean;
  isOwnerCompany: boolean;
  notificationToken: string | null;
  confirmNotificationTokenKey: string | null;
  setIsOwnerCompany: Dispatch<SetStateAction<boolean>>;
  setAuthenticated: (authenticated: boolean) => void;
  setInitialized: (initialized: boolean) => void;
  setErrorMessage: (errMsg: string | null) => void;
  setUser: (user: IUser | null) => void;
  setNotificationToken: Dispatch<SetStateAction<string | null>>;
  setConfirmNotificationTokenKey: Dispatch<SetStateAction<string | null>>;
}

const AuthContext = createContext<AuthState>({
  authenticated: false,
  errorMessage: null,
  initialized: false,
  user: null,
  isOwnerCompany: false,
  notificationToken: null,
  confirmNotificationTokenKey: null,
  setIsOwnerCompany: () => {},
  setAuthenticated: () => {},
  setInitialized: () => {},
  setErrorMessage: () => {},
  setUser: () => {},
  setNotificationToken: () => {},
  setConfirmNotificationTokenKey: () => {},
});

export const AuthProvider: React.FC = ({ children }) => {
  const [notificationToken, setNotificationToken] = useState<string | null>(
    null
  );
  const [authenticated, setAuthenticated] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [user, setUser] = useState<IUser | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>('');
  const [isOwnerCompany, setIsOwnerCompany] = useState(false);
  const [confirmNotificationTokenKey, setConfirmNotificationTokenKey] =
    useState<string | null>(null);

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        errorMessage,
        initialized,
        user,
        isOwnerCompany,
        notificationToken,
        confirmNotificationTokenKey,
        setIsOwnerCompany,
        setAuthenticated,
        setInitialized,
        setErrorMessage,
        setUser,
        setNotificationToken,
        setConfirmNotificationTokenKey,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
