import { ICompanyServerResponse } from 'app/types/company';
import { isEmpty } from 'app/utils/helper';
import { IUser, IUserServerResponse } from './../types/user';
import { apiWrapper, axiosClientOwner } from './axiosClient';
import { mappingServerUser } from './userAPI';

export type TLoginArgs = {
  username: string;
  password: string;
};
export type TUpdateMeArgs = {
  name?: string;
  email?: string;
  phoneNumber?: string;
  birthday?: string;
  username?: string;
  password?: string;
  oldPassword?: string;
};
export type TUpdateMeRes = string;
export type TRegisterArgs = {
  name: string;
  username: string;
  password: string;
};
export type TRegisterRes = string;

type ApiLoginArgs = {
  user_name: string;
  password: string;
};
type ApiLoginRes = {
  is_owner_company?: number | string | null;
  user: IUserServerResponse;
  token: string;
  company_info: ICompanyServerResponse;
};
type ApiGetMeRes = {
  is_owner_company?: number | string | null;
  user: IUserServerResponse;
  company_info: ICompanyServerResponse;
};
type ApiUpdateMeArgs = {
  name?: string | null;
  email?: string | null;
  telephone?: string | null;
  birthday?: string | null;
  user_name?: string | null;
  password?: string | null;
  old_password?: string | null;
};
type ApiUpdateMeRes = string;
// type ApiRegisterArgs = {
//   name: string,
//   username: string,
//   password: string,
// }
type ApiRegisterRes = string;

const login = async (
  username: string,
  password: string
): Promise<{ user: IUser | null; token: string; isOwnerCompany: boolean }> => {
  const result = await apiWrapper.post<ApiLoginArgs, ApiLoginRes>(
    '/api/login',
    {
      user_name: username,
      password: password,
    },
    {
      contentType: 'multipart/form-data',
    }
  );

  return {
    user: mappingServerUser(result.user, result.company_info),
    token: result.token,
    isOwnerCompany:
      result.is_owner_company &&
      parseInt(result.is_owner_company.toString()) === 1
        ? true
        : false,
  };
};

const logout = async (): Promise<boolean> => {
  return Promise.resolve(true);
};

const getMe = async (): Promise<{
  user: IUser | null;
  isOwnerCompany: boolean;
}> => {
  const result = await apiWrapper.get<ApiGetMeRes>('/api/user');

  return {
    user: mappingServerUser(result.user, result.company_info),
    isOwnerCompany:
      result.is_owner_company &&
      parseInt(result.is_owner_company.toString()) === 1
        ? true
        : false,
  };
};

const updateMe = async (params: TUpdateMeArgs): Promise<TUpdateMeRes> => {
  const result = await apiWrapper.post<ApiUpdateMeArgs, ApiUpdateMeRes>(
    '/api/user',
    {
      name: isEmpty(params.name) ? '' : params.name,
      email: isEmpty(params.email) ? '' : params.email,
      telephone: isEmpty(params.phoneNumber) ? '' : params.phoneNumber,
      birthday: isEmpty(params.birthday) ? '' : params.birthday,
      user_name: isEmpty(params.username) ? '' : params.username,
      password: isEmpty(params.password) ? '' : params.password,
      old_password: isEmpty(params.oldPassword) ? '' : params.oldPassword,
    },
    {
      contentType: 'multipart/form-data',
    }
  );

  return result;
};

const registerTempUser = async (
  params: TRegisterArgs
): Promise<TRegisterRes> => {
  const result = await axiosClientOwner.post<any, ApiRegisterRes>(
    '/api/register',
    {
      username: params.username,
      name: params.name,
      password: params.password,
    },
    {
      headers: {
        'content-type': 'application/json',
      },
    }
  );

  return result;
};
const authAPI = { login, logout, getMe, updateMe, registerTempUser };

export default authAPI;
