import { IReportParamDataServerResponse } from './report';

export interface IStationType {
  id: number;
  name: string;
  key: string;
  color: string | null;
}

export interface IStationCompany {
  id: number;
}

export interface IStation {
  id: number;
  name: string;
  path: string;
  link: string;
  structureType: EStationStructureType;
  icon?: JSX.Element;
  status: EStationStatus;
  type: IStationType;
  dataType: EStationDataType;
  fetchInterval: number;
  company: IStationCompany;
  color: string | null;
  configParams: IStationConfigParam[];
  lastTimeSyncData?: Date;
  lastTimeHaveData?: Date;
  users: string;
}

export enum EStationStatus {
  OK = 'OK',
  DISCONNECTED = 'DISCONNECTED',
  MAINTENANCE = 'MAINTENANCE',
  ERROR_REPORT = 'ERROR_REPORT',
}

export enum EStationConfigParamsStatus {
  OK = 'OK',
  ERROR = 'ERROR',
  MAINTENANCE = 'MAINTENANCE',
}

export enum EStationDataType {
  THONG_TU_24 = 'thong-tu-24',
  INVENTIA_102 = 'inventia-102',
  NGHI_DINH_47_1 = 'nghi-dinh-47-1',
  NGHI_DINH_47_2 = 'nghi-dinh-47-2',
  NGHI_DINH_47_3 = 'nghi-dinh-47-3',
}

export enum EStationStructureType {
  ROOT = 'root',
  DATE = 'date',
  SUB_FOLDER = 'sub-folder',
}

export interface IStationConfigParamTracking {
  value: string;
  time: string | number | Date;
}

export interface IStationConfigParam {
  id: number;
  stationId: number;
  name: string;
  code: string;
  unit: string;
  status: EStationConfigParamsStatus;
  standardMin: number | null;
  standardMax: number | null;
  nationalMin: number | null;
  nationalMax: number | null;
  chartMin: number | null;
  chartMax: number | null;
  warningMin: number | null;
  warningMax: number | null;
  trackings: IStationConfigParamTracking[];
  value: number | null;
  stationCode: string | null;
}

export interface IStatisticStation {
  total: number;
  monitors: IStatisticMonitorStation[];
  statuses: IStatisticStatusStation[];
}

export interface IStatisticMonitorStation {
  id: number;
  name: string;
  value: number;
}

export interface IStatisticStatusStation {
  id: number;
  status: EStationStatus;
  value: number;
}

export interface IStationServerResponse {
  id: number;
  name: string;
  station_type_id: number;
  station_type_name: string;
  station_type_image?: string | null;
  path: string;
  color: string | null;
  server_path: string;
  time_warning: number;
  structure_type: EStationStructureTypeServerResponse;
  data_type: number;
  station_code: string;
  start_sync_data_time: string;
  sync_old_data_status: string;
  current_time_sync_old_data: string;
  last_time_sync_data: string;
  last_time_have_data: string;
  company_id: number;
  status: EStationStatusServerResponse;
  fume_info: {
    [key: string]: IStationConfigParamServerResponse;
  };
  time_sync: number;
  users: string;
}

export interface IStationTypeServerResponse {
  id: number;
  company_id: number;
  name: string;
  color: string | null;
  image: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export interface IStationConfigParamServerResponse {
  id: number;
  station_id: number;
  fume_code: string;
  fume_column: string;
  fume_name: string;
  fume_unit: string;
  national_limit_max: number | null;
  national_limit_min: number | null;
  standard_limit_max: number | null;
  standard_limit_min: number | null;
  limit_chart_min: number | null;
  limit_chart_max: number | null;
  warning_limit_min: number | null;
  warning_limit_max: number | null;
  status: EStationConfigParamStatusServerResponse;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
  station_code: string | null;
  data: IReportParamDataServerResponse[];
}

export enum EStationStatusServerResponse {
  GOOD = 1,
  LOST_CONNECTION = 2,
  MAINTENANCE = 3,
  ERROR = 4,
}

export enum EStationConfigParamStatusServerResponse {
  GOOD = '00',
  MAINTENANCE = '01',
  ERROR = '02',
}

export enum EServerStationGroupBy {
  TYPE = 'station_type',
  STATUS = 'status',
}

export enum EStationDataTypeServerResponse {
  THONG_TU_24 = 0,
  INVENTIA_102 = 1,
  NGHI_DINH_47_1 = 2,
  NGHI_DINH_47_2 = 3,
  NGHI_DINH_47_3 = 4,
}

export enum EStationStructureTypeServerResponse {
  ROOT = 0,
  DATE = 1,
  SUB_FOLDER = 2,
}
