import configSystemAPI, {
  TCreateStationTypeArgs,
  TDeleteStationTypeArgs,
  TDeleteStationTypeRes,
  TGetOwnerCompanyArgs,
  TGetOwnerCompanyRes,
  TUpdateStationTypeArgs,
  TUpdateStationTypeRes,
} from 'app/api/configSystemAPI';
import { TCreateStationRes } from 'app/api/stationAPI';
import ConfigSystemContext from 'app/context/config-system';
import { IPaginationQuery, IPaginationResponse } from 'app/types/pagination';
import { IStationType } from 'app/types/station';
import { useContext } from 'react';
import { useMutation } from 'react-query';

function useConfigSystem() {
  const { defaultLogoURL, systemCompany, setDefaultLogoURL, setSystemCompany } =
    useContext(ConfigSystemContext);
  const { mutateAsync: fetchSystemImageUrl } = useMutation<string, any>(
    () => {
      return configSystemAPI.getSystemImageUrl();
    },
    {
      onSuccess: data => {
        setDefaultLogoURL(data);
      },
      onError: err => {
        setDefaultLogoURL(null);
      },
    }
  );
  const { mutateAsync: getSystemCompany } = useMutation<
    TGetOwnerCompanyRes,
    any,
    TGetOwnerCompanyArgs
  >(param => {
    return configSystemAPI.getSystemCompany(param);
  });
  const { mutateAsync: fetchStationTypes } = useMutation<
    IPaginationResponse<IStationType>,
    any,
    IPaginationQuery
  >(params => {
    const { page, limit } = params;
    return configSystemAPI.fetchStationTypes({
      page,
      limit,
    });
  });
  const { mutateAsync: createStationType } = useMutation<
    TCreateStationRes,
    any,
    TCreateStationTypeArgs
  >(({ name, color }) => {
    return configSystemAPI.createStationType({
      name,
      color,
    });
  });
  const { mutateAsync: updateStationType } = useMutation<
    TUpdateStationTypeRes,
    any,
    TUpdateStationTypeArgs
  >(({ name, id, color }) => {
    return configSystemAPI.updateStationType(id, {
      name,
      color,
    });
  });
  const { mutateAsync: deleteStationType } = useMutation<
    TDeleteStationTypeRes,
    any,
    TDeleteStationTypeArgs
  >(({ id }) => {
    return configSystemAPI.deleteStationType(id);
  });

  return {
    defaultLogoURL,
    systemCompany,
    fetchSystemImageUrl,
    fetchStationTypes,
    createStationType,
    updateStationType,
    deleteStationType,
    getSystemCompany,
    setSystemCompany,
  };
}

export default useConfigSystem;
